import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, CardHeader, Divider, NativeSelect, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const transport = axios.create({
    withCredentials: true
});
const ToPay = (props) => {
    const [duration, changeDuration] = useState('daily_revenue');
    const [sum, changeSum] = useState(0);

    useEffect(() => {
      
        let reqUrl = 'https://api.foodia.sk/settings/getSettings.php?type=' + duration;
        transport.get(reqUrl)
            .then(res => {
                changeSum(res.data[duration].toFixed(2));
            }) 
        
    })


    return(
            <Card classes={{
                root:'dashboardItem'
            }}>
                <Box margin={3}>
                <CardHeader title={<h4>Tržba za posledných 24h</h4>} />
                    <CardContent>
                        <h4 style={{textAlign:"center", color:"#8bbc51", fontWeight:'bold'}}>{sum} €</h4>
                    </CardContent>
                </Box>
            </Card>
    )
}

export default ToPay;