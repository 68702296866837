import "../../App.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { GreenCheckbox, CssTextField } from "../../GlobalComponents";
import { FormControlLabel } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState, useEffect } from "react";

const UpdatePassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className='loginMain'>
      <div className='headerLogin'></div>
      <div className='container loginForm'>
        <p>Nastavenie nového hesla</p>
        <p></p>
        <div>
          <div class='form-group'>
            {/*
                  <label for="exampleInputEmail1">Nové heslo</label>
                  <input type="password" class="form-control loginInputStyle" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="*******" onChange={(e) => props.setNewPass(e.target.value)} />
                  <small id="emailHelp" class="form-text text-muted"></small>
									*/}
            <TextField
              label='Vaše nové heslo'
              type={showPassword ? "text" : "password"}
              variant='outlined'
              color='primary'
              fullWidth
              onChange={(e) => props.setNewPass(e.target.value)}
              margin='normal'
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </div>
          <div class='form-group'>
            {/*
                  <label for="exampleInputEmail1">Zopakuj nové heslo</label>
                  <input type="password" class="form-control loginInputStyle" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="*******" onChange={(e) => props.setNewPassRepeat(e.target.value)} />
                  <small id="emailHelp" class="form-text text-muted"></small>
									*/}

            <TextField
              label='Zopakujte vaše nové heslo'
              type={showPassword ? "text" : "password"}
              variant='outlined'
              color='primary'
              fullWidth
              onChange={(e) => props.setNewPassRepeat(e.target.value)}
              margin='normal'
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </div>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            size='large'
            onClick={() => props.updatePassword()}
          >
            Zmeniť heslo
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
