import "./menu.scss";
import React, { useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	HashRouter,
	Redirect,
} from "react-router-dom";
import LoaderSpinner from "../loader";
import TableInstance from "./TableInstance";
import EditProduct from "./EditProduct";
import axios from "axios";
import foodia_background_logo from "./foodiadžsede.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import { GreenSwitch, ENDPOINT } from "../../GlobalComponents";
import { GreenCheckbox } from "../../GlobalComponents";
import { CssTextField } from "../../GlobalComponents";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import ToolTipCustom from ".././Settings/Settings";
import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { AddOutlined, Fastfood } from "@material-ui/icons";
import {useTranslation, withTranslation} from "react-i18next";

const MySwal = withReactContent(Swal);
const transport = axios.create({
	withCredentials: true,
});

class Menu extends React.Component {
	constructor(props) {
		super(props);

		this.myRef = React.createRef();

		this.state = {
			items: [],
			loading: true,
			adding: false,
			sections: [],
			additions: [],
			alertShown: false,
			alertMessage: "",
			csvModalOpen:false,			
			csvModalLoading:false,			
			csvFile:null,			
			reqData: {
				name: "",
				price: 0,
				content: [],
				section: "",
				available: true,
				additions: [],
				sizes: [],
			},
			relativeValues: {
				newProdName: "",
				newProdPrice: "",
				newSectionName: "",
				editedSectionName:"",	  
				additionsShown: false,
				contentShown: false,
				sizesShown: false
			},
			newSectionModalOpen:false,
			newSectionName:'',
			newSectionError:false,
			newSectionLoading:false,		
						newSectionHelperText:'',			
						newItemModalOpen:false,
						newItemName:'',
						newItemNameError:false,
						newItemPriceError:false,
						newItemModalHelperText:'',
						newItemLoading:false
		};

		this.loadData = this.loadData.bind(this);
		this.prepareName = this.prepareName.bind(this);
		this.preparePrice = this.preparePrice.bind(this);
		this.prepareSection = this.prepareSection.bind(this);
		this.prepareContent = this.prepareContent.bind(this);
		this.prepareSizes = this.prepareSizes.bind(this);
		this.prepareAvailable = this.prepareAvailable.bind(this);
		this.prepareAdditions = this.prepareAdditions.bind(this);
		this.setEditShown = this.setEditShown.bind(this);	  
		this.openModalSection = this.openModalSection.bind(this);
		this.openModalProduct = this.openModalProduct.bind(this);
		this.updateSections = this.updateSections.bind(this);
		this.delSection = this.delSection.bind(this);
		this.createProduct = this.createProduct.bind(this);
		this.removeItem = this.removeItem.bind(this);
		this.updateProduct = this.updateProduct.bind(this);
		this.setProdName = this.setProdName.bind(this);
		this.setProdPrice = this.setProdPrice.bind(this);
		this.setNewSectionName = this.setNewSectionName.bind(this);
		this.setAdditionsShow = this.setAdditionsShow.bind(this);
		this.setContentShown = this.setContentShown.bind(this);
		this.setSizesShow = this.setSizesShow.bind(this);
		this.editSectionName = this.editSectionName.bind(this);	  
		this.setEditedSectionName = this.setEditedSectionName.bind(this);	  
		this.changeRouteTo = this.changeRouteTo.bind(this);	  
		this.disableSection = this.disableSection.bind(this);			
		this.enableSection = this.enableSection.bind(this);			
		this.uploadCsvFile = this.uploadCsvFile.bind(this);			
		this.addSection = this.addSection.bind(this);		
		this.addItem = this.addItem.bind(this);		
	} 

	addSection(){
			
				this.updateSections(
								this.state.newSectionName
				);
	}

	uploadCsvFile(){
						this.setState({
										csvModalLoading:true
						})

					const formData = new FormData();
					formData.append('csvFile', this.state.csvFile);

					transport
						.post(ENDPOINT + "/importcsv", formData)
						.then(res => {
										this.setState({
														csvModalOpen:false,
														csvModalLoading:false
										}, () => {
														this.loadData()
														this.props.showAlert('success', res.data.data)
										})

						}).catch(err => {
										this.setState({
														csvModalOpen:false,
														csvModalLoading:false
										}, () => {
														this.props.showAlert('error', JSON.stringify(err))
													
										})

						})
					

	}

	disableSection(section){

					let parsedSection = section.replaceAll(' ', '-')

							 let payload = JSON.stringify({
								 disableSection: section,
							 });

							 transport
								 .post(ENDPOINT + "/settings", payload)
								 .then((res) => {
									 this.changeRouteTo('#/menu/' + parsedSection);		
									 this.loadData();
									 this.props.showAlert("success", "Uložené");
									
								 })
								 .catch((err) => {
									 this.props.showAlert("error", "Chyba servera");
									 console.log(err);
								 });
	}

	enableSection(section){

					let parsedSection = section.replaceAll(' ', '-')

							 let payload = JSON.stringify({
								 enableSection: section,
							 });

							 transport
								 .post(ENDPOINT + "/settings", payload)
								 .then((res) => {
									 this.changeRouteTo('#/menu/' + parsedSection);		
									 this.loadData();
									 this.props.showAlert("success", "Uložené");
									
								 })
								 .catch((err) => {
									 this.props.showAlert("error", "Chyba servera");
									 console.log(err);
								 });
	}

	changeRouteTo(path){

    window.location.href = path;
  }

	setEditedSectionName(val) {
		this.setState({
			relativeValues: {
				editedSectionName: val,
			},
		});
	}

	editSectionName(section, e){

				  MySwal.fire({
					  titleText: "Upraviť sekciu",
					  html: <EditSection setName={this.setEditedSectionName} currentName={section}  />,
					  showConfirmButton: true,
					  confirmButtonText: "Uložiť",
					  showCloseButton: true,
				  }).then((result) => {
					  if (result.isConfirmed) {

						let newName = this.state.relativeValues.editedSectionName;	 
						let sections = this.state.sections;	 

						// get old value section from the sections array
										
						for(let i in sections){
								  
							if(sections[i] == section){
									  sections.splice(i, 1, this.state.relativeValues.editedSectionName); 
							}
								  
						}

							 let payload = JSON.stringify({
								 sections: sections,
								 old_value:section,
								 new_value:this.state.relativeValues.editedSectionName,		
							    option:'patch'			
							 });

							 transport
								 .post(ENDPOINT + "/settings", payload)
								 .then((res) => {

									 this.changeRouteTo('#/menu/' + this.state.relativeValues.editedSectionName);		
									 this.loadData();
									 this.props.showAlert("success", "Uložené");
									
								 })
								 .catch((err) => {
									 this.props.showAlert("error", "Chyba servera");
									 console.log(err);
								 });

					  }


					 });
	}

	setAdditionsShow(val) {
		this.setState({
			relativeValues: {
				newProdName: this.state.relativeValues
					.newProdName,
				newProdPrice: this.state.relativeValues
					.newProdPrice,
				newSectionName: this.state.relativeValues
					.newSectionName,
				additionsShown: val,
				contentShown: this.state.relativeValues
					.contentShown,
				sizesShown: this.state.relativeValues
					.sizesShown,
			},
		});
	}

	setSizesShow(val) {
		this.setState({
			relativeValues: {
				newProdName: this.state.relativeValues
					.newProdName,
				newProdPrice: this.state.relativeValues
					.newProdPrice,
				newSectionName: this.state.relativeValues
					.newSectionName,
				additionsShown: this.state.relativeValues
					.additionsShown,
				contentShown: this.state.relativeValues
					.contentShown,
				sizesShown: val,
			},
		});
	}

	setContentShown(val) {
		this.setState({
			relativeValues: {
				newProdName: this.state.relativeValues
					.newProdName,
				newProdPrice: this.state.relativeValues
					.newProdPrice,
				newSectionName: this.state.relativeValues
					.newSectionName,
				additionsShown: this.state.relativeValues
					.additionsShown,
				contentShown: val,
				sizesShown: this.state.relativeValues
					.sizesShown,
			},
		});
	}

	loadData() {
		transport
			.get(ENDPOINT + "/settings")
			.then((res) => {
				let sections = res.data.data.payload.sections;

				if (sections == null) {
					this.setState({
						sections: [],
					});

					return false;
				}

				let decoded = JSON.parse(sections);
				let sectionsArr = Object.values(decoded);

				this.setState({
					sections: sectionsArr,
				});
			})
			.then(() => {
				transport
					.get(ENDPOINT + "/product")
					.then((res) => {
						if (
							res.data.data ==
							undefined
						) {
							this.setState({
								loading: false,
								items: [],
							});
						} else {
							this.setState({
								items:
									res.data
										.data,
								loading: false,
								adding: false,
							});
						}

						/*
                        let spolu = document.getElementsByClassName('spoluLink')[0];
                    
                        
                            var evt = document.createEvent("MouseEvents"); 
                            evt.initMouseEvent("click", true, true, window, 
                                            0, 0, 0, 0, 0, false, false, false, false, 0, null); 
                            spolu.dispatchEvent(evt); 
                        }
                        */
					});
			});
	}

	setNewSectionName(val) {
		this.setState({
			relativeValues: {
				newSectionName: val,
			},
		});
	}
	openModalSection() {
					this.setState({
									newSectionModalOpen:true
					})
	}

	setProdName(val) {
		this.setState({
			relativeValues: {
				newProdName: val,
				newProdPrice: this.state.relativeValues
					.newProdPrice,
			},
		});
	}
	setProdPrice(val) {
		this.setState({
			relativeValues: {
				newProdPrice: val,
				newProdName: this.state.relativeValues
					.newProdName,
			},
		});
	}

	openModalProduct(section) {
					this.setState({
									currentSection:section,
									newItemModalOpen:true
					})

					{/*
		MySwal.fire({
			titleText: "Nová položka",
			showCloseButton: true,
			html: (
				<NewProduct
					setName={this.setProdName}
					setPrice={this.setProdPrice}
					relValues={this.state.relativeValues}
				/>
			),
			showConfirmButton: true,
			confirmButtonText: "Vytvoriť",
		}).then((result) => {
			if (result.isConfirmed) {
				this.createProduct(
					this.state.relativeValues.newProdName,
					this.state.relativeValues.newProdPrice,
					section
				);
			}
		});
		*/}
	}

	addItem(){

					let price = this.state.newItemPrice
					let name = this.state.newItemName
					let section = this.state.currentSection

					console.log(isNaN(price))

					if(isNaN(price)){
									this.setState({
													newItemPriceError:true,
													newItemPriceHelperText:'Hodnota nie je valídne číslo',
													newItemLoading:false
									})
									return
					}

					if(!name){
									this.setState({
													newItemNameError:true,
													newItemLoading:false

									})
									return
					}

					this.setState({
									newItemLoading:true
					})


			let payload = JSON.stringify({
				name: name,
				price: price,
				section: section,
				available: true,
			})

							transport
								.post(ENDPOINT + "/product", payload)
								.then((res) => {
									this.loadData();
									this.setState({
													newItemPrice:'',
													newItemName:'',
													newItemPriceError:false,
													newItemNameError:false,
													newItemModalOpen:false,
													newItemLoading:false,
													newItemPriceHelperText:''
									});
									this.props.showAlert(
										"success",
										"Uložené"
									);
								})
								.catch((err) => {
									this.props.showAlert(
										"error",
										"Chyba servera"
									);
								});
	}

	updateSections(newSection) {

		if (newSection.length == 0) {
						this.setState({
										newSectionError:true,
										newSectionLoading:false,
										newSectionHelperText:'Toto pole je povinné'
						})
						return;
		}

		if(newSection.includes('-')){
						this.setState({
										newSectionError:true,
										newSectionLoading:false,
										newSectionHelperText:'"-" nie je povolený znak'
						})
						return;

		}

		this.setState({
									newSectionLoading:true
					})

		let payload = JSON.stringify({
			sections: [...this.state.sections, newSection],
		});

		transport
			.post(ENDPOINT + "/settings", payload)
			.then((res) => {
				this.loadData();
				this.setState({
								newSectionLoading:false,
								newSectionModalOpen:false,
								newSectionError:false,
								newSectionName:'',
								newSectionHelperText:''
				})			
				this.props.showAlert("success", "Uložené");
			})
			.catch((err) => {
					this.setState({
								newSectionLoading:false,
								newSectionError:false,
								newSectionName:'',
								newSectionHelperText:''
					})			
				this.props.showAlert("error", JSON.stringify(err));
				console.log(err)			
			});
	}

	showAlert(message) {
		if (!this.state.alertShown) {
			this.setState({
				alertMessage: message,
				alertShown: true,
			});

			setTimeout(() => {
				this.setState({
					alertShown: false,
				});
			}, 3000);
		} else {
			return false;
		}
	}

	delSection(section) {
					const { t } = this.props

					if(!window.confirm(t('menu_section.are_you_sure_delete_section') + section + "?")){
									return
					}
		let sectionToDel = section.replace(/-/g, " ");

		let newSectionsArr = this.state.sections.filter(
			(section) => section != sectionToDel
		);

		let payload = JSON.stringify({
			sections: newSectionsArr,
		});

		transport
			.post(ENDPOINT + "/settings", payload)
			.then((res) => {
				for (
					let i = 0;
					i < this.state.items.length;
					i++
				) {
					if (
						this.state.items[
							i
						].section.replace(/-/g, " ") ==
						sectionToDel
					) {
						this.removeItem(
							this.state.items[i]
								.meal_ID
						);
					} else {
						continue;
					}
				}
			})
			.then((res) => {
				this.loadData();
							window.location.href="#/menu/all"
				this.props.showAlert("success", "Odstránené");
			})
			.catch((err) => {
				this.props.showAlert("error", "Chyba");
				console.log(err);
			});
	}

	createProduct(name, price, section) {
		let regexp = /^\d+(\.\d{1,2})?$/;

		if (regexp.test(price) && name.length !== 0) {
			let payload = JSON.stringify({
				name: name,
				price: price,
				section: section,
				available: true,
			});

			transport
				.post(ENDPOINT + "/product", payload)
				.then((res) => {
					this.loadData();
					this.setState({
						relativeValues: {
							newProdName: "",
							newProdPrice: null,
						},
					});
					this.props.showAlert(
						"success",
						"Uložené"
					);
				})
				.catch((err) => {
					this.props.showAlert(
						"error",
						"Chyba servera"
					);
				});
		} else {
			MySwal.fire({
				titleText: "Nesprávny formát",
				icon: "error",
			});
		}
	}

	removeItem(id) {
		transport
			.delete(ENDPOINT + "/product/" + id)
			.then((res) => {
				console.log(res);
				this.loadData();
				this.props.showAlert("success", "Odstránené");
			})
			.catch((err) => err);
	}

	editProduct = (item) => {
		let parsedContent = JSON.parse(item.content);
		let parsedAdditions = JSON.parse(item.additions);
		let parsedSizes = JSON.parse(item.sizes);

		if (parsedContent == null) {
			parsedContent = [];
		}
		if (parsedAdditions == null) {
			parsedAdditions = [];
		}
		if (parsedSizes == null) {
			parsedSizes = [];
		}

		this.setState({
			reqData: {
				name: item.meal_name,
				price: item.price,
				section: item.section,
				content: parsedContent,
				sizes: parsedSizes,
				available: item.available,
				additions: parsedAdditions,
			},
			relativeValues: {
				newProdName: this.state.relativeValues
					.newProdName,
				newProdPrice: this.state.relativeValues
					.newProdPrice,
				newSectionName: this.state.relativeValues
					.newSectionName,
				additionsShown:
					parsedAdditions.length == 0
						? false
						: true,
				contentShown:
					parsedContent.length == 0
						? false
						: true,
				sizesShown:
					parsedSizes.length == 0 ? false : true,
			},
		});

		MySwal.fire({
			html: (
				<EditProduct
					setAdditionsShown={
						this.setAdditionsShow
					}
					setSizesShown={this.setSizesShow}
					setContentShown={this.setContentShown}
					prepareAdditions={this.prepareAdditions}
					sections={this.state.sections}
					prepareName={this.prepareName}
					preparePrice={this.preparePrice}
					prepareSection={this.prepareSection}
					prepareContent={this.prepareContent}
					prepareSizes={this.prepareSizes}
					prepareAvailable={this.prepareAvailable}
					updateProduct={this.updateProduct}
					removeItem={this.removeItem}
					itemData={item}
					itemContent={parsedContent}
					itemAdditions={parsedAdditions}
					itemSizes={parsedSizes}
					allItems={this.state.items}			
				/>
			),
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: "Uložiť",
			showDenyButton: true,
			denyButtonText: "Odstrániť",
			cancelButtonText: "Zrušiť",
			customClass: {
				popup: "editPopup",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				let regexp = /^\d+(\.\d{1,2})?$/;

				if (
					regexp.test(this.state.reqData.price) &&
					this.state.reqData.name.length !== 0
				) {
					console.log(item.meal_ID);
					this.updateProduct(
						item.name,
						item.meal_ID
					);
				} else {
					MySwal.fire({
						titleText: "Nesprávny formát",
						icon: "error",
					});
				}

				this.setState({
					reqData: {
						name: "",
						price: 0,
						content: [],
						section: "",
						available: true,
						additions: [],
					},
				});
			} else if (result.isDenied) {
				this.removeItem(item.meal_ID);
			}
		});
	};
	/**
	 * Functions to setstate in func components
	 */
	prepareName(value) {
		this.setState({
			reqData: {
				name: value,
				price: this.state.reqData.price,
				section: this.state.reqData.section,
				content: this.state.reqData.content,
				additions: this.state.reqData.additions,
				available: this.state.reqData.available,
				sizes: this.state.reqData.sizes,
			},
		});
	}

	preparePrice(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: value,
				section: this.state.reqData.section,
				content: this.state.reqData.content,
				additions: this.state.reqData.additions,
				available: this.state.reqData.available,
				sizes: this.state.reqData.sizes,
			},
		});
	}

	prepareSection(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: this.state.reqData.price,
				section: value,
				content: this.state.reqData.content,
				additions: this.state.reqData.additions,
				available: this.state.reqData.available,
				sizes: this.state.reqData.sizes,
			},
		});
	}

	prepareContent(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: this.state.reqData.price,
				section: this.state.reqData.section,
				available: this.state.reqData.available,
				additions: this.state.reqData.additions,
				content: value,
				sizes: this.state.reqData.sizes,
			},
		});
	}

	prepareSizes(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: this.state.reqData.price,
				section: this.state.reqData.section,
				available: this.state.reqData.available,
				additions: this.state.reqData.additions,
				content: this.state.reqData.content,
				sizes: value,
			},
		});
	}

	prepareAdditions(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: this.state.reqData.price,
				section: this.state.reqData.section,
				available: this.state.reqData.available,
				content: this.state.reqData.content,
				sizes: this.state.reqData.sizes,
				additions: value,
			},
		});
	}

	prepareAvailable(value) {
		this.setState({
			reqData: {
				name: this.state.reqData.name,
				price: this.state.reqData.price,
				section: this.state.reqData.section,
				content: this.state.reqData.content,
				additions: this.state.reqData.additions,
				sizes: this.state.reqData.sizes,
				available: value,
			},
		});
	}

	updateProduct(previous_name, id) {
		let payload = JSON.stringify({
			name: this.state.reqData.name,
			available: this.state.reqData.available,
			price: this.state.reqData.price,
			section: this.state.reqData.section,
			content: this.state.relativeValues.contentShown
				? this.state.reqData.content
				: [],
			sizes: this.state.relativeValues.sizesShown
				? this.state.reqData.sizes
				: [],
			previous_name: previous_name,
			meal_id: id,
			additions: this.state.relativeValues.additionsShown
				? this.state.reqData.additions
				: [],
		});

		transport
			.patch(ENDPOINT + "/product", payload)
			.then((res) => {
				this.loadData();
				this.props.showAlert("success", "Uložené");
			})
			.catch((err) => {
				this.props.showAlert("error", "Chyba servera");
			});
	}


	setEditShown(e,state){
		let classArr = e.target.className.split(' ');
		if(state && classArr.length > 1){
			
		}else if(classArr.length){

		}

	}

	componentDidMount() {
		this.loadData();
	}



	render() {

		const { t } = this.props;

		if (this.state.loading) {
			return <LoaderSpinner />;
		} else {
			return (
				<div id="mainMenu">
					<HashRouter basename="/menu">
						<div>
							<ul id="sectionsList">
								<NavLink
									activeClassName="activeSection"
									className="sectionLink"
									to="/all"
								>
												{t('menu_section.all')}
								</NavLink>
								{this.state
									.sections
									.length ==
								0
									? null
									: this.state.sections.map(
											(
												item
											) => {
												if (
													item ==
													null
												) {
													return null;
												}
												return (
													<NavLink
														activeClassName="activeSection"
														className="sectionLink"
														id={item}	  
														to={
															"/" +
															item.replace(
																/\s/g,
																"-"
															)
														}
													>
														{
															item
														}
															  {/*<IconButton aria-label='edit'><EditIcon  style={{fontSize:'15px', padding:'0px'}} /></IconButton>*/}	  
													</NavLink>
												);
											}
									  )}

								<Tooltip
									title={
										<p className="tooltipText">
														{t('menu_section.new_section')}
										</p>
									}
									placement="top"
								>
									<IconButton
										onClick={
											this
												.openModalSection
										}
									>
										<AddOutlined />
									</IconButton>
								</Tooltip>
							</ul>
						</div>

						<div className="tables">
							<Switch>
								<Route
									exact
									path="/"
									render={() => {
										return (
											<Redirect to="/all" />
										);
									}}
								/>

								{this.state.sections.map(
									(
										item
									) => {
										return (
											<Route
												path={
													"/" +
													item.replace(
														/\s/g,
														"-"
													)
												}
											>
												<TableContainer
													component={
														Paper
													}
													className="tableDiv"
												>
													<Table aria-label="simple table">
														<TableHead>
															<TableRow>
																<TableCell>
																	<b>
																					{t('menu_section.meal_name')}
																	</b>
																</TableCell>
																<TableCell>
																	<b>
																					{t('menu_section.price')}
																	</b>
																</TableCell>
																<TableCell>
																	<b>
																					{t('menu_section.section')}
																	</b>
																</TableCell>
																<TableCell>
																	<b>
																					{t('menu_section.availability')}
																	</b>
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.items.map(
																(
																	food
																) => {
																	if (
																		food.section.replace(
																			/\s/g,
																			"-"
																		) ==
																		item.replace(
																			/\s/g,
																			"-"
																		)
																	) {
																		return (
																			<TableRow
																				onClick={() =>
																					this.editProduct(
																						food
																					)
																				}
																				className="productRow"
																			>
																				<TableCell>
																					{
																						food.meal_name
																					}
																				</TableCell>
																				<TableCell>
																					{
																						food.price
																					}{" "}
																					€
																				</TableCell>
																				<TableCell>
																					{
																						food.section
																					}
																				</TableCell>
																				<TableCell>
																					{food.available ==
																					true ? (
																						<CheckIcon />
																					) : (
																						<ClearIcon />
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	} else {
																		return null;
																	}
																}
															)}
														</TableBody>
													</Table>
												</TableContainer>

												<div className="sectionBtns">
													<Tooltip
														title={
															<p className="tooltipText">
																			{t('menu_section.add_item')}
															</p>
														}
														placement="bottom"
													>
														<IconButton
															size="large"
															onClick={() =>
																this.openModalProduct(
																	item
																)
															}
														>
															<i className="material-icons">
																add
															</i>
														</IconButton>
													</Tooltip>
											      <Tooltip
														title={
															<p className="tooltipText">
																			{t('menu_items.edit_section')}
															</p>
														}
														placement="bottom"
													>
														<IconButton
															size="large"
															onClick={(e) =>
																this.editSectionName(
																	item,e
																)
															}
														>
															<i className="material-icons">
															edit	
															</i>
														</IconButton>
													</Tooltip>

													<Tooltip
														title={
															<p className="tooltipText">
																			{t('menu_section.delete_section')}
															</p>
														}
														placement="bottom"
													>
														<IconButton
															size="large"
															onClick={() =>
																this.delSection(
																	item
																)
															}
														>
															<i className="material-icons">
																delete
															</i>
														</IconButton>
													</Tooltip>


													<Tooltip
														title={
															<p className="tooltipText">
																			{t('menu_section.section_unavailable')}
															</p>
														}
														placement="bottom"
													>
														<IconButton
															size="large"
															onClick={() =>
																this.disableSection(
																	item
																)
															}
														>
															<i className="material-icons">
															visibility_off	
															</i>
														</IconButton>
													</Tooltip>

											<Tooltip
														title={
															<p className="tooltipText">
																			{t('menu_section.section_available')}
															</p>
														}
														placement="bottom"
													>
														<IconButton
															size="large"
															onClick={() =>
																this.enableSection(
																	item
																)
															}
														>
															<i className="material-icons">
															visibility	
															</i>
														</IconButton>
													</Tooltip>

												</div>
											</Route>
										);
									}
								)}

								<Route path="/all">
									<TableContainer
										component={
											Paper
										}
										className="tableDiv"
									>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>
														<b>
																		{t('menu_section.meal_name')}
														</b>
													</TableCell>
													<TableCell>
														<b>
																		{t('menu_section.price')}
														</b>
													</TableCell>
													<TableCell>
														<b>
																		{t('menu_section.section')}
														</b>
													</TableCell>
													<TableCell>
														<b>
																		{t('menu_section.availability')}
														</b>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.items.map(
													(
														food
													) => {
														return (
															<TableRow
																onClick={() =>
																	this.editProduct(
																		food
																	)
																}
																className="productRow"
															>
																<TableCell>
																	{
																		food.meal_name
																	}
																</TableCell>
																<TableCell>
																	{
																		food.price
																	}{" "}
																	€
																</TableCell>
																<TableCell>
																	{
																		food.section
																	}
																</TableCell>
																<TableCell>
																	{food.available ==
																	true ? (
																		<CheckIcon />
																	) : (
																		<ClearIcon />
																	)}
																</TableCell>
															</TableRow>
														);
													}
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Route>
							</Switch>
						</div>
									<Fab variant="extended" color="primary" aria-label="add" className="uploadCsvFab" onClick={(e) => this.setState({csvModalOpen: true})}>
													<CloudUploadIcon style={{marginRight:'5px'}}/>
													{t('menu_section.import_csv')}
												</Fab>
					</HashRouter>

								 <Dialog
								open={this.state.csvModalOpen}
								onClose={(e) => this.setState({csvModalOpen: !this.state.csvModalOpen})}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<DialogTitle id="alert-dialog-title">{t('menu_section.import_menu_card')}</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
													{t('menu_section.import_csv_detail')} <a href="https://admin.foodia.sk/jedalny_listok_priklad.csv" download>{t('menu_section.import_csv_detail2')}</a>.	
									</DialogContentText>
												 <input
												accept=".csv"
												onChange={(e) => this.setState({csvFile:e.target.files[0]})}				 
																type="file"
											/>
								</DialogContent>
								<DialogActions>
									<Button onClick={(e) => this.setState({csvModalOpen: false})} color="primary">
													{t('menu_section.cancel')}
									</Button>
												<Button onClick={this.uploadCsvFile} color="primary" autoFocus variant="contained" disabled={this.state.csvModalLoading}>
													{t('menu_section.import')}
									</Button>
								</DialogActions>
							</Dialog>


						 <Dialog
								open={this.state.newSectionModalOpen}
								onClose={(e) => this.setState({newSectionError: false, newSectionModalOpen:false, newSectionHelperText:''})}
										 maxWidth="sm"
								fullWidth={true}		 
							>
								<DialogTitle id="alert-dialog-title">{t('menu_section.new_section')}</DialogTitle>
								<DialogContent>
									<DialogContentText>
													{t('menu_section.add_new_section')}
									</DialogContentText>
											<Box component="div" style={{display:'flex', flexDirection:'column'}}>
																				<TextField label={t('menu_section.section_name')} variant="filled" 
																					value={this.state.newSectionName}
																					onChange={(e) => this.setState({newSectionName:e.target.value})}			
																					error={this.state.newSectionError}
																					helperText={this.state.newSectionHelperText}
																								onKeyPress={(e) => e.key == 'Enter' ? this.addSection() : null}			
																				/>
											</Box>
								</DialogContent>
								<DialogActions>
												<Button onClick={(e) => this.setState({newSectionError: false, newSectionModalOpen:false, newSectionHelperText:''})} color="primary">
																{t('menu_section.cancel')}
									</Button>
												<Button onClick={this.addSection} color="primary" autoFocus variant="contained" disabled={this.state.newSectionLoading}>
																{t('menu_section.add')}
									</Button>
								</DialogActions>
							</Dialog>

					 <Dialog
								open={this.state.newItemModalOpen}
								onClose={(e) => this.setState({newItemPriceError: false, newItemNameError:false, newItemPriceHelperText:'', newItemModalOpen:false})}
								fullWidth={true}
						
							>
								<DialogTitle id="alert-dialog-title">{t('menu_section.new_item')}</DialogTitle>
								<DialogContent>
									<DialogContentText>
													{t('menu_section.add_new_item')}
									</DialogContentText>
											<Box component="div" style={{display:'flex', flexDirection:'column'}}>
																				<TextField label={t('menu_section.item_name')} variant="filled" 
																					value={this.state.newItemName}
																					onChange={(e) => this.setState({newItemName:e.target.value})}			
																					error={this.state.newItemNameError}
																					onKeyPress={(e) => e.key == 'Enter' ? this.addItem() : null}			
																				/>

															<br/>

																		<TextField label={t('menu_section.price')} variant="filled" 
																					value={this.state.newItemPrice}
																					onChange={(e) => this.setState({newItemPrice:e.target.value})}			
																					error={this.state.newItemPriceError}
																					InputProps={{
																						endAdornment: <InputAdornment position="start">€</InputAdornment>,
																					}}
																					helperText={this.state.newItemPriceHelperText}	
																					onKeyPress={(e) => e.key == 'Enter' ? this.addItem() : null}			
																				/>
											</Box>
								</DialogContent>
								<DialogActions>
												<Button onClick={(e) => this.setState({newItemPriceError: false, newItemNameError:false, newItemPriceHelperText:'', newItemModalOpen:false})} color="primary">
																{t('menu_section.cancel')}
									</Button>
												<Button onClick={this.addItem} color="primary" autoFocus variant="contained" disabled={this.state.newItemLoading}>
																{t('menu_section.add')}
									</Button>
								</DialogActions>
							</Dialog>


				</div>
			);
		}
	}
}

const LinkSection = (props) => {
	let sectionHref = "#" + props.section;
	return (
		<li>
			<a
				data-toggle="tab"
				href={sectionHref}
				className="nav-link"
			>
				{props.section.replace(/-/g, " ")}
			</a>
		</li>
	);
};

const NewProduct = (props) => {
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");

	function handleName(val) {
		setName(val);
		props.setName(val);
	}
	function handlePrice(val) {
		setPrice(val);
		props.setPrice(val);
	}

	return (
		<div className="newProductModal">
			<CssTextField
				placeholder="Názov Položky"
				className="halfWidth"
				value={name}
				onChange={(e) => handleName(e.target.value)}
			/>
			<CssTextField
				placeholder="Cena"
				className="halfWidth"
				value={price}
				onChange={(e) => handlePrice(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="start">
							€
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};

const NewSection = (props) => {
	const [name, setName] = useState("");

	function handleName(val) {
		setName(val);
		props.setName(val);
	}

	return (
		<div className="newSectionModal">
			<CssTextField
				placeholder="Názov Sekcie"
				className="halfWidth"
				value={name}
				onChange={(e) => handleName(e.target.value)}
			/>
		</div>
	);
};

const EditSection = (props) => {
	const [name, setName] = useState(props.currentName);

	function handleName(val) {
		setName(val);
		props.setName(val);
	}

	return (
		<div className="newSectionModal">
			<CssTextField
				placeholder="Názov Sekcie"
				className="halfWidth"
				value={name}
				onChange={(e) => handleName(e.target.value)}
			/>
		</div>
	);
};

const MenuTranslated = withTranslation('common')(Menu)
export default MenuTranslated 
