import "./App.scss";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

// All necessary components

import Objednavky from "./components/objednavky/objednavky";
import Dashboard from "./components/dashboard/dashboard";
import Menu from "./components/menu/menu";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import Reviews from "./components/Reviews/Reviews";
import Customers from "./components/Customers/Customers";
import Sofer from "./components/sofer/sofer";
import LoaderSpinner from "./components/loader";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Loader from "react-loader-spinner";
import Register from "./components/Register/Register";
import Settings from "./components/Settings/Settings";
import History from "./components/History/History";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import UpdatePassword from "./components/ChangePassword/UpdatePassword";
import Push from "push.js";
import alertAudio from "./assets/alert.mp3";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ChangeLanguage from "./changeLanguage.js";
import Fab from "@material-ui/core/Fab";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Link from "@material-ui/core/Link";

// libraries / dependencies

import axios from "axios";
import foodia_logo from "./assets/foodia_dztien.png";
import foodia_logo_square from "./assets/foodia_profilepic.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GridMenuIcon } from "@material-ui/data-grid";
import {
  Close,
  DriveEtaTwoTone,
  EventNoteTwoTone,
  ExitToApp,
  HistoryTwoTone,
  HomeTwoTone,
  Lock,
  LockOpen,
  MenuBookRounded,
  MenuOpenOutlined,
  MenuRounded,
  RestaurantMenuTwoTone,
  SettingsTwoTone,
} from "@material-ui/icons";
import { theme, ENDPOINT, CssTextField } from "./GlobalComponents.js";
import { IconButton, LinearProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Modal from "@material-ui/core/Modal";
import HelpIcon from "@material-ui/icons/Help";
import Fade from "@material-ui/core/Fade";
import FacebookIcon from "@material-ui/icons/Facebook";
import SupervisorAccountTwoToneIcon from "@material-ui/icons/SupervisorAccountTwoTone";
import RateReviewTwoToneIcon from "@material-ui/icons/RateReviewTwoTone";
import Alert from "@material-ui/lab/Alert";

const MySwal = withReactContent(Swal);

function AlertCustom(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

let source;

export const transport = axios.create({
  withCredentials: true,
});

let geoID;
let interval;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      isLoading: true,
      register: false,
      email: "",
      password: "",
      driverLocation: undefined,
      locationWatching: false,
      driver_name: "",
      settings: {},
      sidenavLocked: false,
      checkOrders: false,
      inKitchen: 0,
      onWay: 0,
      new: 0,
      interval: null,
      orders: [],
      passwordChange: false,
      passwordEmailSent: false,
      alertOpen: false,
      alertMessage: "",
      alertType: "",
      lastFiveOrders: null,
      subdomain: "",
      newPassword: "",
      newPasswordRepeat: "",
      onboardingDialogOpen: false,
      subdomain: "",
      restaurant_name: "",
      facebook_link: "",
      phone_num: "",
      subdomain_error: false,
      restaurant_name_error: false,
      generatingSubdomain: false,
      helperOpen: false,
      helpDialog: false,
      newsAlert: false,
    };

    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.logout = this.logout.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.registerFunc = this.registerFunc.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.watchMe = this.watchMe.bind(this);
    this.stopWatching = this.stopWatching.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.loadSettings = this.loadSettings.bind(this);
    this.lockSidenav = this.lockSidenav.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.checkNewOrders = this.checkNewOrders.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.startChecking = this.startChecking.bind(this);
    this.stopChecking = this.stopChecking.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.changeRouteTo = this.changeRouteTo.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.createSubdomain = this.createSubdomain.bind(this);
    this.setDomain = this.setDomain.bind(this);
    this.setFoodiaUrl = this.setFoodiaUrl.bind(this);
    this.setNewPass = this.setNewPass.bind(this);
    this.setNewPassRepeat = this.setNewPassRepeat.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.startListening = this.startListening.bind(this);
    this.processNewData = this.processNewData.bind(this);
    this.generateOrderingPage = this.generateOrderingPage.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
    this.switchSystem = this.switchSystem.bind(this);
  }

  componentDidMount() {
    transport.get(ENDPOINT + "/check").then((res) => {
      if (res.data.data == "Logged In") {
        this.setState({
          isLoggedIn: true,
          isLoading: false,
        });

        this.loadSettings();
        this.loadOrders();
        if (window.location.hash == "#/") {
          this.changeRouteTo("#/dashboard");
        }

        /*
          
          TODO Improve stepper and check value in database, not localstorage?
								if(localStorage.getItem('helperOpen') == 0){
												this.setState({
																helperOpen:false
												})
								}else{
												this.setState({
																helperOpen:true
												})
								}
                */
      } else {
        /*
          if(window.location.hash !== '#/register'){
            this.changeRouteTo('#/login');
          }
					*/
        switch (window.location.hash) {
          case "#/register":
            this.changeRouteTo("#/register");
            break;
          case "#/update-password":
            this.changeRouteTo("#/update-password");
            break;

          case "#/forgot-password":
            this.changeRouteTo("#/forgot-password");
            break;

          default:
            this.changeRouteTo("#/login");
            break;
        }

        this.setState({
          isLoading: false,
        });
      }
    });
  }

  loginSuccess() {
    this.setState({
      isLoggedIn: true,
    });

    this.changeRouteTo("#/dashboard");

    this.loadSettings();
    this.loadOrders();
  }

  setNewPass(val) {
    this.setState({
      newPassword: val,
    });
  }

  setNewPassRepeat(val) {
    this.setState({
      newPasswordRepeat: val,
    });
  }

  updatePassword() {
    let newPass = this.state.newPassword;
    let repeat = this.state.newPasswordRepeat;

    console.log(newPass);
    console.log(repeat);

    if (newPass !== repeat) {
      this.showAlert("warning", "Heslá sa nezhodujú");
    } else {
      this.setState({
        isLoading: true,
      });
      let payload = {};
      payload.password = newPass;

      transport
        .post(ENDPOINT + "/reset-password/update", payload)
        .then((res) => {
          this.setState({
            isLoading: false,
          });
          this.showAlert("success", res.data.data);
          this.changeRouteTo("#/login");
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          this.showAlert("error", "Chyba");
        });
    }
  }

  lockSidenav() {
    this.setState({
      sidenavLocked: this.state.sidenavLocked ? false : true,
    });
  }

  openNav() {
    if (window.innerWidth > 900) {
      document.getElementById("mySidenav").style.width = "200px";
    } else {
      document.getElementById("mySidenav").style.width = "100%";
    }
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  changeRouteTo(path) {
    window.location.href = path;
  }

  /**
   * Function to log out the current user
   */

  logout() {
    this.setState({
      isLoading: true,
    });

    transport.get(ENDPOINT + "/logout").then((res) => {
      this.setState({
        isLoggedIn: false,
        isLoading: false,
      });

      this.changeRouteTo("#/login");
    });
  }

  /**
   * Function to log in a user
   */

  /**
   * Function to register a new user
   */

  registerFunc() {
    this.setState({
      isLoading: true,
    });

    if (!this.state.email || !this.state.password) {
      this.setState({
        isLoading: false,
        alertOpen: true,
        alertType: "warning",
        alertMessage: "Vyplňte všetky políčka",
      });

      return false;
    }

    let payload = JSON.stringify({
      email: this.state.email,
      username: "",
      password: this.state.password,
    });

    transport
      .post("https://api.foodia.sk/register", payload)
      .then((res) => {
        alert("Skontrolujte si emailovú adresu pre potvrdenie");

        /*
        this.setState({
          isLoading:false,
          alertOpen:true,
          alertType:'info',
          alertMessage:'Skontrolujte si emailovú adresu pre potvrdenie'
        })
				*/
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertType: "error",
          alertMessage: "Chyba serveru, skúste to neskôr alebo nás kontaktujte",
        });
      });
  }

  setEmail = (val) => {
    this.setState({
      email: val,
    });
  };

  setPassword = (val) => {
    this.setState({
      password: val,
    });
  };

  setUsername = (val) => {
    this.setState({
      username: val,
    });
  };

  setDriver = (val) => {
    this.setState({
      driver_name: val,
    });
  };

  watchMe() {
    this.setState({
      locationWatching: true,
    });

    if (navigator.geolocation) {
      geoID = navigator.geolocation.watchPosition(this.getLocation);
    } else {
      alert("Geolokacia nie je podporovana v tomto prehliadaci.");
    }
  }

  getLocation(position) {
    let reqURL = "https://api.foodia.sk/location/updateLocation.php";
    let payload = JSON.stringify({
      location: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
      name: this.state.driver_name,
    });

    this.setState({
      driverLocation: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });

    transport.post(reqURL, payload).catch((err) => err);
  }

  stopWatching() {
    this.setState({
      locationWatching: false,
    });

    navigator.geolocation.clearWatch(geoID);
  }

  loadSettings() {
    transport.get(ENDPOINT + "/settings").then((res) => {
      this.setState(
        {
          settings: res.data.data.payload,
          isLoading: false,
        },
        () => {
          console.log(this.state.settings.foodia_url);

          if (this.state.settings.foodia_url == null) {
            this.createSubdomain();
          }
        }
      );
    });
  }

  // function to continually check new orders

  checkNewOrders() {
    let prevOrders = this.state.new + this.state.inKitchen + this.state.onWay;

    console.log("func checkNewOrders");
    console.log("prev orders:" + prevOrders);

    transport
      .get("https://api.foodia.sk/orders")
      .then((res) => {
        this.setState(
          {
            new: res.data.new.length,
            inKitchen: res.data.inKitchen.length,
            onWay: res.data.onWay.length,
            orders: res.data.new.concat(res.data.inKitchen, res.data.onWay),
          },
          () => {
            let nextOrders = this.state.orders.length;

            if (prevOrders < nextOrders) {
              console.log("NEW !");

              this.playAudio();

              Push.create("Nová objednávka !");

              MySwal.fire({
                title: "Nová objednávka !",
                showConfirmButton: false,
                customClass: {
                  popup: "newOrderPopup",
                  title: "newOrderTitle",
                  container: "newOrderPopup",
                },
                timer: 5000,
              });
            }
          }
        );
      })
      .catch((err) => {
        alert(err);
      });
  }

  loadOrders() {
    this.setState({
      isLoading: true,
      new: 0,
      inKitchen: 0,
      onWay: 0,
    });

    transport.get(ENDPOINT + "/orders").then((res) => {
      var sumTotal = res.data.data.data.reduce(function (prev, cur) {
        return prev + parseFloat(cur.price);
      }, 0);

      let lastFive = res.data.data.data.slice(
        res.data.data.data.length - 5,
        res.data.data.data.length
      );

      for (let i in res.data.data.data) {
        switch (res.data.data.data[i].status) {
          case "new":
            this.setState({
              new: this.state.new + 1,
            });
            break;
          case "inKitchen":
            this.setState({
              inKitchen: this.state.inKitchen + 1,
            });
            break;
          case "onWay":
            this.setState({
              onWay: this.state.onWay + 1,
            });
            break;
          default:
            console.log("default");
            break;
        }
      }
      this.setState({
        isLoading: false,
        orders: res.data.data.data,
        lastFiveOrders: lastFive,
        sumTotal: sumTotal,
      });
    });
  }

  playAudio() {
    var audio = new Audio(alertAudio);
    audio.play();

    setTimeout(function () {
      audio.pause();
      audio.currentTime = 0;
    }, 5000);
  }

  // stop constantly checking for new orders

  stopChecking() {
    clearInterval(interval);

    this.setState({
      checkOrders: false,
    });
    console.log("Checking stopped");
    this.showAlert("success", "Notifikácie vypnuté");
  }

  startChecking() {
    interval = setInterval(this.checkNewOrders, 5000);

    this.setState({
      checkOrders: true,
    });

    console.log("Checking started");
    this.showAlert("success", "Notifikácie aktivované");
  }

  showAlert(type, message) {
    this.setState({
      alertOpen: true,
      alertType: type,
      alertMessage: message,
    });
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      alertOpen: false,
    });
  }

  // forgot password function to send verification email

  forgotPassword(view = false) {
    if (view) {
      this.setState({
        passwordChange: true,
      });

      return false;
    }

    this.setState({
      isLoading: true,
    });

    let payload = {};
    payload.email = this.state.email;

    transport.post(ENDPOINT + "/reset-password", payload).then((res) => {
      this.setState({
        passwordEmailSent: true,
        isLoading: false,
      });

      console.log(res);
    });
  }

  // allow the user to create a subdomain

  setDomain(val) {
    this.setState(
      {
        subdomain: val,
      },
      () => {
        console.log(this.state.domain);
      }
    );
  }

  setFoodiaUrl(val) {
    this.setState({
      settings: {
        ...this.state.settings,
        foodia_url: val,
      },
    });
  }

  createSubdomain() {
    this.setState({
      onboardingDialogOpen: true,
    });
  }

  generateOrderingPage() {
    if (this.state.subdomain.length == 0) {
      this.setState({
        subdomain_error: true,
      });

      return;
    }

    if (this.state.restaurant_name.length == 0) {
      this.setState({
        restaurant_name_error: true,
      });

      return;
    }

    this.setState({
      generatingSubdomain: true,
    });

    let payload = JSON.stringify({
      foodia_url: this.state.subdomain,
      restaurant_name: this.state.restaurant_name,
      restaurant_phone: this.state.phone_num,
      facebook_url: this.state.facebook_link,
    });

    transport
      .post(ENDPOINT + "/settings", payload)
      .then((res) => {
        this.loadSettings();
        //this.showAlert('success', 'Objednávkový systém vytvorený na adrese ' + 'https://' + this.state.subdomain + '.foodia.sk, pre link a ďaľšie nastavenia sa presuňte do nastavení v ľavej lište.' );
        alert(
          "Objednávkový systém vytvorený na adrese " +
            "https://" +
            this.state.subdomain +
            ".foodia.sk, pre link a ďaľšie nastavenia sa presuňte do nastavení v ľavej lište. Alebo nás jednoducho kontaktujte na 0903 740 155 / info@foodia.sk"
        );
        this.setState({
          generatingSubdomain: false,
          onboardingDialogOpen: false,
        });
      })
      .catch((err) => {
        this.loadSettings();
        this.setState({
          generatingSubdomain: false,
        });
        this.showAlert("error", JSON.stringify(err));
      });
  }

  processNewData(orders) {
    console.log("orders: " + orders.length);
    console.log("this.state.orders: " + this.state.orders.length);
    if (orders.length > this.state.orders.length) {
      this.setState({
        new: 0,
        inKitchen: 0,
        onWay: 0,
      });

      console.log("NEW !");

      this.playAudio();

      Push.create("Nová objednávka !");

      MySwal.fire({
        title: "Nová objednávka !",
        showConfirmButton: false,
        customClass: {
          popup: "newOrderPopup",
          title: "newOrderTitle",
          container: "newOrderPopup",
        },
        timer: 5000,
      });

      var sumTotal = orders.reduce(function (prev, cur) {
        return prev + parseFloat(cur.price);
      }, 0);

      let lastFive = orders.slice(orders.length - 5, orders.length);

      for (let i in orders) {
        switch (orders[i].status) {
          case "new":
            this.setState({
              new: this.state.new + 1,
            });
            break;
          case "inKitchen":
            this.setState({
              inKitchen: this.state.inKitchen + 1,
            });
            break;
          case "onWay":
            this.setState({
              onWay: this.state.onWay + 1,
            });
            break;
          default:
            console.log("default");
            break;
        }
      }

      this.setState({
        orders: orders,
        lastFiveOrders: lastFive,
      });
    } else {
      this.setState({
        orders: orders,
      });
      console.log("No new orders");
    }
  }

  startListening() {
    if (this.state.checkOrders) {
      this.setState({
        checkOrders: false,
      });

      source.close();

      return true;
    } else {
      this.setState({
        checkOrders: true,
      });

      if (typeof EventSource !== "undefined") {
        source = new EventSource(
          "https://updates.foodia.sk/update.php?id=" +
            this.state.settings.cust_id
        );
        source.onmessage = (e) => this.processNewData(JSON.parse(e.data));
        //source.onerror = e => this.showAlert('error', 'Nepodarilo sa načítať najnovšie objednávky, obnovte stránku');
      } else {
        alert("Prehliadac nepodporuje pozadovane technologie");
      }
    }
  }

  switchSystem(val) {
    this.setState({
      isLoading: true,
    });

    let payload = JSON.stringify({
      running: val,
    });

    transport.post(ENDPOINT + "/settings", payload).then((res) => {
      this.setState({
        isLoading: false,
      });

      this.showAlert("success", "Uložené");
      this.loadSettings();
      /*

										if(val && this.props.settings.facebook_page_token && this.props.settings.facebook_page_id){
														window.location.href='#/social-media?newPost=true'
										}
										*/
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.isLoggedIn && !this.state.isLoading) {
      return (
        <HashRouter>
          {this.state.newsAlert ? (
            <Alert
              severity='info'
              onClose={() => this.setState({ newsAlert: false })}
            >
              Novinka! SMS Notifikácie o nových objednávkach, zapnite si ich v
              nastaveniach -{" "}
              <Link
                target='_blank'
                href='https://www.foodia.sk/blog/spatna-vazba/'
                color='primary'
              >
                alebo si o novej funkcii prečítajte tu.
              </Link>
            </Alert>
          ) : null}
          <div id='main'>
            <div className='header'>
              <div className='headerContent'>
                <Button
                  variant='text'
                  onClick={this.openNav}
                  classes={{
                    root: "btnHamb",
                    label: "btnLabel",
                    startIcon: "btnStart",
                  }}
                >
                  <MenuIcon
                    classes={{
                      root: "iconHamb",
                    }}
                  />
                  <span className='headerText'>Menu</span>
                </Button>

                <img
                  src={foodia_logo_square}
                  className='logoHeader'
                  height='40'
                />

                <div>
                  {/*
													<ChangeLanguage />
													*/}
                  {this.state.settings.running == 1 ? (
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => this.switchSystem(0)}
                      id='switch_on'
                    >
                      Vypnúť systém
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => this.switchSystem(1)}
                      id='switch_off'
                    >
                      Zapnúť systém
                    </Button>
                  )}

                  <Tooltip
                    title={
                      <p className='tooltipText'>
                        {t("turn_on_off_notifications")}
                      </p>
                    }
                  >
                    <IconButton
                      variant='text'
                      onClick={this.startListening}
                      classes={{
                        root: "btnHamb",
                        label: "btnLabel",
                      }}
                    >
                      {this.state.checkOrders ? (
                        <NotificationsActiveIcon />
                      ) : (
                        <NotificationsNoneIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    variant='text'
                    onClick={this.logout}
                    classes={{
                      root: "btnHamb logoutBtn",
                      label: "btnLabel",
                    }}
                  >
                    <ExitToApp />
                  </IconButton>
                </div>
              </div>

              <div>
                {this.state.checkOrders ? (
                  <LinearProgress
                    classes={{
                      root: "primaryColor",
                      bar: "secondaryColor",
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div>
              <div id='mySidenav' className='sidenav'>
                <div className='sidenavHeader'>
                  <IconButton
                    classes={{
                      root: "closeBtn",
                    }}
                    onClick={this.closeNav}
                  >
                    <Close />
                  </IconButton>
                  <IconButton
                    style={{ marginRight: "10px" }}
                    onClick={this.lockSidenav}
                  >
                    {this.state.sidenavLocked ? <Lock /> : <LockOpen />}
                  </IconButton>
                </div>
                <hr />
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/dashboard'
                >
                  <p>
                    <HomeTwoTone />
                    <i></i>
                    {t("home")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/objednavky'
                >
                  <p>
                    <EventNoteTwoTone />
                    <i></i>
                    {t("orders")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/sofer'
                >
                  <p>
                    <DriveEtaTwoTone />
                    <i></i>
                    {t("driver")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/menu'
                >
                  <p>
                    <RestaurantMenuTwoTone />
                    <i></i>
                    {t("menu")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/historia'
                >
                  <p>
                    <HistoryTwoTone />
                    <i></i>
                    {t("history")}
                  </p>
                </NavLink>
                <hr />
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/social-media'
                >
                  <p>
                    <FacebookIcon />
                    <i></i>
                    {t("social_media")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/customers'
                >
                  <p>
                    <SupervisorAccountTwoToneIcon />
                    <i></i>
                    {t("customers")}
                  </p>
                </NavLink>
                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/reviews'
                >
                  <p>
                    <RateReviewTwoToneIcon />
                    <i></i>
                    {t("reviews")}
                  </p>
                </NavLink>
                <hr />

                <NavLink
                  activeClassName='activeNav'
                  className='navItem'
                  onClick={this.state.sidenavLocked ? null : this.closeNav}
                  to='/nastavenia'
                >
                  <p>
                    <SettingsTwoTone />
                    <i></i>
                    {t("settings")}
                  </p>
                </NavLink>
              </div>

              <Switch>
                <Route path='/social-media'>
                  <SocialMedia showAlert={this.showAlert} />
                </Route>
                <Route path='/reviews'>
                  <Reviews
                    showAlert={this.showAlert}
                    settings={this.state.settings}
                  />
                </Route>
                <Route path='/customers'>
                  <Customers />
                </Route>
                <Route path='/menu'>
                  <Menu showAlert={this.showAlert} />
                </Route>
                <Route path='/objednavky'>
                  <Objednavky
                    checkOrders={this.state.checkOrders}
                    loadOrders={this.loadOrders}
                    orders={this.state.orders}
                    showAlert={this.showAlert}
                    new={this.state.new}
                    inKitchen={this.state.inKitchen}
                    onWay={this.state.onWay}
                  />
                </Route>
                <Route path='/sofer'>
                  <Sofer
                    watchMe={this.watchMe}
                    stopWatching={this.stopWatching}
                    locationWatching={this.state.locationWatching}
                    driverLocation={this.state.driverLocation}
                    setDriver={this.setDriver}
                    driver_name={this.state.driver_name}
                    showAlert={this.showAlert}
                  />
                </Route>
                <Route path='/dashboard'>
                  <Dashboard
                    settings={this.state.settings}
                    loadSettings={this.loadSettings}
                    new={this.state.new}
                    inKitchen={this.state.inKitchen}
                    onWay={this.state.onWay}
                    showAlert={this.showAlert}
                  />
                </Route>
                <Route path='/nastavenia'>
                  <Settings showAlert={this.showAlert} />
                </Route>
                <Route path='/historia' sumTotal={this.state.sumTotal}>
                  <History />
                </Route>
              </Switch>
            </div>

            <Snackbar
              open={this.state.alertOpen}
              autoHideDuration={6000}
              onClose={() => this.setState({ alertOpen: false })}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <AlertCustom
                onClose={() => this.setState({ alertOpen: false })}
                severity={this.state.alertType}
              >
                {this.state.alertMessage}
              </AlertCustom>
            </Snackbar>

            <Dialog
              open={this.state.onboardingDialogOpen}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                {"Môžeme začať!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Na začiatok budeme od vás potrebovať tieto informácie, aby sme
                  vytvorili vašu vlastnú objednávaciu webstránku.
                </DialogContentText>

                <Box
                  component='div'
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <TextField
                    label='Doména *'
                    variant='filled'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='start'>
                          .foodia.sk
                        </InputAdornment>
                      ),
                    }}
                    value={this.state.subdomain}
                    onChange={(e) =>
                      this.setState({ subdomain: e.target.value })
                    }
                    error={this.state.subdomain_error}
                    helperText='Toto pole je povinné'
                  />
                  <br />
                  <TextField
                    label='Názov reštaurácie *'
                    variant='filled'
                    value={this.state.restaurant_name}
                    onChange={(e) =>
                      this.setState({ restaurant_name: e.target.value })
                    }
                    helperText='Toto pole je povinné'
                    error={this.state.restaurant_name_error}
                  />
                  <br />
                  <TextField
                    label='Link na facebookovú stránku'
                    variant='filled'
                    value={this.state.facebook_link}
                    onChange={(e) =>
                      this.setState({ facebook_link: e.target.value })
                    }
                  />
                  <br />
                  <TextField
                    label='Tel. Číslo'
                    variant='filled'
                    value={this.state.phone_num}
                    onChange={(e) =>
                      this.setState({ phone_num: e.target.value })
                    }
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={this.generateOrderingPage}
                  disabled={this.state.generatingSubdomain}
                >
                  Vytvoriť
                </Button>
              </DialogActions>
            </Dialog>

            <Fade in={this.state.helperOpen}>
              <Card className='suggestionsCard' variant='outlined'>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ fontWeight: "bold" }}>
                      <HelpIcon /> ĎAĽŠIE KROKY
                    </h4>
                    <IconButton
                      color='primary'
                      onClick={() => this.setState({ helperOpen: false })}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>

                  <Divider />
                  <List component='nav' aria-label='main mailbox folders'>
                    <ListItem
                      button
                      onClick={() => (window.location.href = "#/menu")}
                    >
                      <ListItemText primary='1. Importujte si váš jedálny lístok v CSV' />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => (window.location.href = "#/nastavenia")}
                    >
                      <ListItemText primary='2. Upravte si primárnu farbu objednávacej stránky' />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => (window.location.href = "#/nastavenia")}
                    >
                      <ListItemText primary='3. Zapnite si email notifikácie o nových objednávkach' />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      this.setState({ helperOpen: false });
                      localStorage.setItem("helperOpen", 0);
                    }}
                  >
                    Už nezobrazovať
                  </Button>
                </CardActions>
              </Card>
            </Fade>

            <Fab
              onClick={() => this.setState({ helpDialog: true })}
              color='primary'
              aria-label='add'
              style={{ position: "fixed", left: "1em", bottom: "1em" }}
            >
              <LiveHelpIcon />
            </Fab>

            <Dialog open={this.state.helpDialog}>
              <DialogTitle id='alert-dialog-title'>{"Podpora"}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Ak máte akékoľvek problémy alebo otázky ohladom systému,
                  neváhajte nás kontaktovať.
                </DialogContentText>

                <Box
                  component='div'
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PhoneIcon
                      style={{ fontSize: "40px", marginBottom: "15px" }}
                    />
                    <Link href='tel:0903740155'> 0903 740 155</Link>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon
                      style={{ fontSize: "40px", marginBottom: "15px" }}
                    />
                    <Link href='mailto:info@foodia.sk'> info@foodia.sk </Link>
                  </div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => this.setState({ helpDialog: false })}
                >
                  Zavrieť
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </HashRouter>
      );
    } else if (this.state.isLoading) {
      return <LoaderSpinner />;
    } else {
      return (
        <HashRouter>
          <Switch>
            <Route path='/login'>
              <Login
                loginSuccess={this.loginSuccess}
                forgotPassword={this.forgotPassword}
                changeRouteTo={this.changeRouteTo}
              />
            </Route>
            <Route path='/register'>
              <Register
                changeRouteTo={this.changeRouteTo}
                submitForm={this.registerFunc}
                setEmail={this.setEmail}
                setPassword={this.setPassword}
                setUsername={this.setUsername}
              />
            </Route>
            <Route path='/forgot-password'>
              <ChangePassword
                setEmail={this.setEmail}
                passwordEmailSent={this.state.passwordEmailSent}
                forgotPassword={this.forgotPassword}
              />
            </Route>
            <Route path='/update-password'>
              <UpdatePassword
                setNewPass={this.setNewPass}
                setNewPassRepeat={this.setNewPassRepeat}
                updatePassword={this.updatePassword}
              />
            </Route>
          </Switch>

          <Snackbar
            open={this.state.alertOpen}
            autoHideDuration={6000}
            onClose={() => this.setState({ alertOpen: false })}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => this.setState({ alertOpen: false })}
              severity={this.state.alertType}
            >
              {this.state.alertMessage}
            </Alert>
          </Snackbar>
        </HashRouter>
      );
    }
  }
}

const NewSubdomain = (props) => {
  const [domain, setDomain] = useState("");

  function handleChange(val) {
    var pattern = /\s/g;
    var result = domain.match(pattern);
    if (result == true) {
      alert("Medzeri nie sú povolené");
      return false;
    }

    setDomain(val);
    props.setDomain(val);
  }

  return (
    <div className='subdomainOuter'>
      <p>
        Nastavte si doménu pre váš objednávkový systém a do pár sekúnd
        príjimajte objednávky
      </p>
      <div className='subdomainDiv'>
        <CssTextField
          onChange={(e) => handleChange(e.target.value)}
          placeholder='meno-reštaurácie'
        />
      </div>
      <div className='subdomainView'>
        <p>https://</p>
        <p>{domain}</p>
        <p>.foodia.sk</p>
      </div>
    </div>
  );
};

const AppTranslated = withTranslation("common")(App);
export default AppTranslated;
