import './sofer.scss';
import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from 'axios';
import Note from '../objednavky/note';
import { getLCP } from 'web-vitals';
import LoaderSpinner from '../loader';
import H from "@here/maps-api-for-javascript";
import foodia_background_logo from './foodiadžsede.svg';
import OrderItem from './soferItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {ENDPOINT} from '../../GlobalComponents';


import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const MySwal = withReactContent(Swal);


const transport = axios.create({
    withCredentials: true
});


export default class Sofer extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isLoading:true,
            orderNum:undefined,
            orders:undefined,
            customerLocation:undefined,
            driver_name:'',
            drivers:[]
       
        }

        this.loadData = this.loadData.bind(this);
        this.getAddressLocation = this.getAddressLocation.bind(this);
        this.mapsSelector = this.mapsSelector.bind(this);
        this.reload = this.reload.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.handleChange = this.handleChange.bind(this);
    
    }


    componentDidMount(){
        this.reload();
    }

    

    mapsSelector(lat, lng) {

        if /* if we're on iOS, open in Apple Maps */
          ((navigator.platform.indexOf("iPhone") != -1) || 
           (navigator.platform.indexOf("iPad") != -1) || 
           (navigator.platform.indexOf("iPod") != -1))
          window.open("maps://maps.google.com/maps?daddr="+lat+","+lng+"&amp;ll=");else /* else use Google */
          window.open("https://maps.google.com/maps?daddr=<lat>,<long>&amp;ll=");
    }

    getAddressLocation(address){
        let coors = [];

        const platform = new H.service.Platform({
            apikey: "pXqouIGUFQ97YbLNAM5o6dxosSeAvz4GLqFZTa7aW5g"
        });

        var service = platform.getSearchService();

        service.geocode({
            q: address
        }, (result) => {
          
            if(result.items[0] == undefined){
                return 'Test'
            }else{


                coors.push(result.items[0].position.lat.toString());
                coors.push(result.items[0].position.lng.toString());

              
                //coors = result.items[0].position.lat.toString() + "," + result.items[0].position.lng.toString(); 
                return coors;
            }
            
        });
    }



    reload(){
        this.setState({
            isLoading:true
        });

        transport.get(ENDPOINT + "/orders")
        .then(res => {
    
            this.setState({
              isLoading:false,
              orders:res.data.data.data,
            })
            
        });

       
    }


    loadData(driver_name = sessionStorage.getItem('driver_name')){


        let payload = JSON.stringify({
            name:driver_name
        });

        transport.post("https://api.foodia.sk/location/getDriversOrders.php", payload)
            .then(res => {
                this.setState({
                    orders:res.data
                });
            })
            .then(() => {

                this.setState({
                    isLoading:false
                })
            })
    }

    setLoading(){
        this.setState({
            isLoading:true
        })
    }

    handleChange(e){
        this.setState({
            driver_name:e.target.value
        });

        sessionStorage.setItem('driver_name', e.target.value);

        this.props.setDriver(e.target.value);

        this.setState({
            isLoading:true
        });

        this.loadData(e.target.value);
    }


    changeDriver(){
        sessionStorage.removeItem('driver_name');
        this.props.setDriver('');
        this.setState({
            driver_name:''
        })
    }

    render(){
        if(this.state.isLoading){
            return <LoaderSpinner />
        }else{
            return(
                <div>    
                    {/*
                    <div className='driverControls'>
                        {this.props.locationWatching ? <button className='secondaryBtnStyles blueBtn pulse' onClick={this.props.stopWatching}><i class="material-icons">my_location</i></button> : <button className='secondaryBtnStyles' onClick={this.props.watchMe}><i class="material-icons">my_location</i></button>}
                        {<p>Prihlásený <b>{sessionStorage.getItem('driver_name')}</b></p>} <button className='btnStyles' onClick={this.changeDriver}>Zmeniť šoféra</button>
                    </div>
                    */}
                    <div className='driverControls'></div>
        
                    <div className='ordersWrapper'>
                        
                        {this.state.orders.length == 0 ? <img src={foodia_background_logo} className='logoMiddle' /> : this.state.orders.map((order) => { 
                                if(order.status == 'onWay'){
                                    return <OrderItem showAlert={this.props.showAlert} order={order} reload={this.reload} setLoading={this.setLoading} />
                                }
                            })
                        }
                        
                    </div>
                </div>
            )
        }
    }
}
