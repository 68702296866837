import './dashboard.scss';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import LoaderSpinner from '../loader';
import axios from 'axios';
import foodia_background_logo from '../menu/foodiadžsede.svg';
import {Pie} from 'react-chartjs-2';
import ApexCharts from 'apexcharts'
import {ENDPOINT} from '../../GlobalComponents';
import ApexChartApp from './PieChart';
import OnlineOrdering from './onlineOrdering';
import ToPay from './ToPay';
import LastOrders from './LastOrders';
import LastReviews from './LastReviews';
import { Alert, AlertTitle } from '@material-ui/lab';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import BuildIcon from '@material-ui/icons/Build';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ListIcon from '@material-ui/icons/List';
import {useTranslation, withTranslation} from "react-i18next";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


const transport = axios.create({
    withCredentials: true
});

class Dashboard extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            orderData:[],
            new:0,
            inKitchen:0,
            onWay:0,
            status:false,
            loading:true,
            to_pay:0,
            settings:{},
            orders:{},
            today:{
                orders:0,
                revenue:0
            },
            week:{
                orders:0,
                revenue:0
            },
            month:{
                orders:0,
                revenue:0
            },
            displayValues:{
                orders:0,
                revenue:0,
                unresolved:0
            }
        }

        this.handleChangePeriod = this.handleChangePeriod.bind(this);
        this.loadReports = this.loadReports.bind(this);
    }
    
    componentDidMount(){        
        this.setState({
            loading:true
        });
    
        transport.get(ENDPOINT + "/orders")
        .then(res => {
            var sumTotal = res.data.data.data.reduce(function(prev, cur) {
                return prev + parseFloat(cur.price);
            }, 0);
    
            let lastFive = res.data.data.data.slice(res.data.data.data.length - 5,res.data.data.data.length);
    
            for(let i in res.data.data){
                switch(res.data.data[i].status){
                    case 'new':
                        this.setState({
                            new:this.state.new + 1
                        });
                        break;
                    case 'inKitchen':
                        this.setState({
                            inKitchen:this.state.inKitchen + 1
                        });
                        break;
                    case 'onWay':
                        this.setState({
                            onWay:this.state.onWay + 1
                        });
                        break;
                    default:
                        console.log('default');
                        break;
                }
            }
            this.setState({
              orders:res.data.data,
              lastFiveOrders:lastFive
            });

            this.loadReports();
            
        });
    }

    loadReports(){
        transport.get(ENDPOINT + '/reports')
        .then(res => {
            this.setState({
                loading:false,
                today:{
                    orders:res.data.data.today.orders,
                    revenue:res.data.data.today.revenue
                },
                week:{
                    orders:res.data.data.week.orders,
                    revenue:res.data.data.week.revenue
                },
                month:{
                    orders:res.data.data.month.orders,
                    revenue:res.data.data.month.revenue
                },
                displayValues:{
                    orders:res.data.data.today.orders,
                    revenue:res.data.data.today.revenue,
                    unresolved:res.data.data.unresolved
                }
            })
        })
    }

    handleChangePeriod(e){
        console.log(e.target.value);
        switch(e.target.value){
            case 'day':
                this.setState({
                    displayValues:{
                        orders:this.state.today.orders,
                        revenue:this.state.today.revenue,
                        unresolved:this.state.displayValues.unresolved
                    }
                });
                break;
            case 'week':
                this.setState({
                    displayValues:{
                        orders:this.state.week.orders,
                        revenue:this.state.week.revenue,
                        unresolved:this.state.displayValues.unresolved
                    }
                });
                break;
            case 'month':
                this.setState({
                    displayValues:{
                        orders:this.state.month.orders,
                        revenue:this.state.month.revenue,
                        unresolved:this.state.displayValues.unresolved
                    }
                });
                break;
        }
    }

    render(){

				const { t } = this.props;

        if(this.state.loading){
            return   (
                 <LoaderSpinner />
            )
        }else{
            return(
                <div id='mainDashboard'>
                    <div id='dashboardInside'>
                    <div style={{display:'flex', justifyContent:'space-between'}}>                    
                        <h3>{t('dashboard.stats')}</h3>
                            <Select
                            native
                            value={this.state.period}
                            onChange={this.handleChangePeriod}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                            >
                                <option value="day">{t('dashboard.today')}</option>
                                <option value="week">{t('dashboard.week')}</option>
                                <option value="month">{t('dashboard.month')}</option>
                            </Select>

                    </div>
                    <div id='topDashboard'>
                        <div class='dashboardItem topItem'>
                            <span class='dashIcon'><FastfoodIcon /></span>
                            <h2 class='dashNum'><b>{this.state.displayValues.orders}</b></h2>
                            <p>{t('dashboard.orders')}</p>
                        </div>
                        <div class='dashboardItem topItem'>
                            <span class='dashIcon'><EuroSymbolIcon /></span>
                            <h2 class='dashNum'><b>{this.state.displayValues.revenue}</b></h2>
                            <p>{t('dashboard.revenue')}</p>
                        </div>
                        <div class='dashboardItem topItem'>
                        <span class='dashIcon'><ListIcon /></span>
                            <h2 class='dashNum'><b>{this.state.displayValues.unresolved}</b></h2>
                            <p>{t('dashboard.not_done')}</p>
                            
                        </div>
                    </div>
                    {this.props.settings.foodia_url == null ? null : <OnlineOrdering settings={this.props.settings} orderData={[this.state.new,this.state.inKitchen,this.state.onWay]} loadSettings={this.props.loadSettings} showAlert={this.props.showAlert} />}
                    {this.props.settings.foodia_url == null ? null : <LastOrders orders={this.state.lastFiveOrders} />}
                    {this.props.settings.foodia_url == null ? null : <LastReviews />}
                    {this.props.settings.foodia_url == null ? 
                    <Alert severity="info" classes={{
                        root:'.center'
										}} style={{marginTop:'1em'}} >
                        <AlertTitle>Vitajte</AlertTitle>
                        Tu po vytvorení objednávkového systému uvidíte rýchly prehlad <strong>posledných objednávok a ich stav</strong>
                    </Alert> : null}
                    </div>
                </div>
            )
        }
    }
}

const DashboardTranslated = withTranslation('common')(Dashboard)
export default DashboardTranslated
