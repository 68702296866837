import {useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, IconButton, Table, TableBody, TableHead } from '@material-ui/core';
import React from 'react';
import { Tab } from 'bootstrap';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const TableRowCustom = (props) => {

    const [open,setOpen] = useState(false);

    function handleRowOpen(){
        setOpen(!open);
    }

    return(
        <React.Fragment>
            <TableRow>
                <TableCell align='left'><IconButton onClick={handleRowOpen}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
                <TableCell align='left'>#{props.order.orderID}</TableCell>
                <TableCell align="left">{props.order.name}</TableCell>
                <TableCell align="left">{props.order.email}</TableCell>
                <TableCell align="left">{props.order.delivery == 1 ? <CheckIcon /> : <ClearIcon />}</TableCell>
                <TableCell align="left">{props.order.price} €</TableCell>
                <TableCell align="left">{props.order.date}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open}>
                        <Box margin={5}>
                        <h5><b>Obsah objednávky</b></h5>

                            <Table size='small'>
                                <TableHead>
                                    <TableCell><b>Počet</b></TableCell>
                                    <TableCell><b>Názov</b></TableCell>
                                    <TableCell><b>Cena</b></TableCell>
                                </TableHead>
                                <TableBody>                                    
                                    {JSON.parse(props.order.content).map(item => {
                                        return <TableRow>
                                                    <TableCell>{item.amount}x</TableCell>
                                                    <TableCell>{item.food}</TableCell>
                                                    <TableCell>{item.price} €</TableCell>
                                                </TableRow>
                                        })}
                                </TableBody>
                             <ol>
                            
                            </ol>
                            </Table>
                        </Box>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default TableRowCustom;
