import React, { useState , useEffect} from "react";
import Addition from "./Addition";
import "./menu.scss";

import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { GreenSwitch } from "../../GlobalComponents";
import { GreenCheckbox } from "../../GlobalComponents";
import { CssTextField } from "../../GlobalComponents";

const primaryColor = "#8cba51";

const EditProduct = (props) => {
	const [inputOptions, setinputOptions] = useState("");
	const [additionName, changeAdditionName] = useState("");
	const [additionPrice, changeAdditionPrice] = useState("");
	const [sizeName, changeSizeName] = useState("");
	const [sizePrice, changeSizePrice] = useState("");

	// values to be sent
	const [additions, setAdditions] = useState(props.itemAdditions);
	const [content, setContent] = useState(props.itemContent);
	const [sizes, setSizes] = useState(props.itemSizes);
	
	const [name, setName] = useState(props.itemData.meal_name);
	const [price, setPrice] = useState(props.itemData.price);
	const [section, setSection] = useState(props.itemData.section);
	const [available, setAvailable] = useState(props.itemData.available);

	const [showOptions, setShowOptions] = useState(
		content.length == 0 ? false : true
	);
	const [showAdditions, setShowAdditions] = useState(
		additions.length == 0 ? false : true
	);
	const [showSizes, setShowSizes] = useState(
		sizes.length == 0 ? false : true
	);

	useEffect(() => console.log("re-render because x changed:", additions))

	function removeContentItem(val) {
		var filtered = content.filter(function (value, index, arr) {
			if (value !== val) {
				return value;
			}
		});

		setContent(filtered);
		props.prepareContent(filtered);
	}

	function removeAdditionItem(val) {
		var filtered = additions.filter(function (value, index, arr) {
			if (value.name !== val) {
				return value;
			}
		});

		setAdditions(filtered);
		props.prepareAdditions(filtered);
	}

	function removeSizesItem(val) {
		var filtered = sizes.filter(function (value, index, arr) {
			if (value.name !== val) {
				return value;
			}
		});

		setSizes(filtered);
		props.prepareSizes(filtered);
	}

	function changeName(newName) {
		setName(newName);
		props.prepareName(newName);
	}

	function changePrice(newPrice) {
		setPrice(newPrice);
		props.preparePrice(newPrice);
	}

	function changeSection(newSection) {
		setSection(newSection);
		props.prepareSection(newSection);
	}

	function changeAvailable(newAvailable) {
		setAvailable(newAvailable);
		props.prepareAvailable(newAvailable);
	}

	function changeContent() {
		if (inputOptions.length == 0) {
			return false;
		}

		setContent(content.concat(inputOptions));
		props.prepareContent(content.concat(inputOptions));
		setinputOptions("");
	}

	function handleSwitch() {
		setShowOptions(showOptions == true ? false : true);
		props.setContentShown(showOptions == true ? false : true);
	}
	function handleSwitchAdditions() {
		setShowAdditions(showAdditions == true ? false : true);
		props.setAdditionsShown(showAdditions == true ? false : true);
	}

	function handleSwitchSizes() {
		setShowSizes(showSizes == true ? false : true);
		props.setSizesShown(showSizes == true ? false : true);
	}

	function addAddition() {
		if (additionName.length == 0 || additionPrice.length == 0) {
			return false;
		}

		setAdditions(
			additions.concat({
				name: additionName,
				price: additionPrice,
			})
		);

		props.prepareAdditions(
			additions.concat({
				name: additionName,
				price: additionPrice,
			})
		);

		changeAdditionName("");
		changeAdditionPrice("");
	}


	function addGroupAddition(meal){
					let arr_additions = JSON.parse(meal.additions);
					console.log(arr_additions);



						setAdditions(
										additions.concat(arr_additions)
						);


						props.prepareAdditions(
									additions.concat(arr_additions)	
						);
					
	}

	function addSize() {
		if (sizeName.length == 0 || sizePrice.length == 0) {
			return false;
		}

		setSizes(
			sizes.concat({
				name: sizeName,
				price: sizePrice,
			})
		);

		props.prepareSizes(
			sizes.concat({
				name: sizeName,
				price: sizePrice,
			})
		);

		changeSizeName("");
		changeSizePrice("");
	}

	return (
		<div className="modalEditItem">
			<CssTextField
				type="text"
				className="modalInput"
				placeholder="Názov Položky"
				onChange={(e) => changeName(e.target.value)}
				value={name}
			/>
			<CssTextField
				type="text"
				InputProps={{
					endAdornment: (
						<InputAdornment position="start">
							€
						</InputAdornment>
					),
				}}
				className="modalInput"
				placeholder="Cena"
				onChange={(e) => changePrice(e.target.value)}
				value={price}
			/>
			<div>
				<select
					class="swal2-input"
					onChange={(e) =>
						changeSection(e.target.value)
					}
				>
					{props.sections.map((item) => {
						if (
							item ==
							section.replace(
								/-/g,
								" "
							)
						) {
							return (
								<option
									selected
								>
									{item}
								</option>
							);
						}
						return <option>{item}</option>;
					})}
				</select>
			</div>

			<FormControlLabel
				control={
					<GreenSwitch
						checked={showOptions}
						onChange={handleSwitch}
						name="options"
					/>
				}
				label="Prílohy"
			/>

			{showOptions ? (
				<div className="containerShadow">
					<div className="inputContainer">
						<CssTextField
							type="text"
							placeholder="Názov"
							onChange={(e) =>
								setinputOptions(
									e.target
										.value
								)
							}
							value={inputOptions}
						/>
						<IconButton
							type="button"
							className="swal2-button buttonAdd"
							onClick={changeContent}
						>
							<i className="material-icons">
								add
							</i>
						</IconButton>
					</div>

					{content.length == 0 ? null : (
						<TableContainer
							className="freeItemsAdded"
							component={Paper}
							className="tableDiv"
						>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>
											<b>
												Meno
											</b>
										</TableCell>
										<TableCell align="right">
											<b>
												Odstrániť
											</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{content.map(
										(
											item
										) => {
											return (
												<TableRow>
													<TableCell>
														{
															item
														}
													</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() =>
																removeContentItem(
																	item
																)
															}
														>
															<i class="material-icons">
																close
															</i>
														</IconButton>
													</TableCell>
												</TableRow>
											);
										}
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			) : null}

			<FormControlLabel
				control={
					<GreenSwitch
						checked={showAdditions}
						onChange={handleSwitchAdditions}
						name="additions"
					/>
				}
				label="Prídavky"
			/>
					

			{showAdditions ? (
				<div className="containerShadow">


					<div className="inputContainer">
						<CssTextField
							type="text"
							placeholder="Názov"
							onChange={(e) =>
								changeAdditionName(
									e.target
										.value
								)
							}
							value={additionName}
						/>
						<CssTextField
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										€
									</InputAdornment>
								),
							}}
							type="text"
							placeholder="Cena"
							onChange={(e) =>
								changeAdditionPrice(
									e.target
										.value
								)
							}
							value={additionPrice}
						/>
						<IconButton
							type="button"
							className="swal2-button buttonAdd"
							onClick={addAddition}
						>
							<i className="material-icons">
								add
							</i>
						</IconButton>
									
 
					</div>

				<List>
								{props.allItems.map(val => {
												if(val.additions !== null){
																if(JSON.parse(val.additions).length > 0 && val.section == 'Prídavky'){

																				return(
																								<ListItem button onClick={() => addGroupAddition(val)}>
																								
																									<ListItemText primary={val.meal_name} />
																								</ListItem>
																				) 
																}
												}
								})}
       
      </List>

					{additions.length == 0 ? null : (
						<TableContainer
							className="freeItemsAdded"
							component={Paper}
							className="tableDiv"
						>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>
											<b>
												Meno
											</b>
										</TableCell>
										<TableCell>
											<b>
												Cena
											</b>
										</TableCell>
										<TableCell align="right">
											<b>
												Odstrániť
											</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{additions.map(
										(
											item
										) => {
											return (
												<TableRow>
													<TableCell>
														{
															item.name
														}
													</TableCell>
													<TableCell>
														{
															item.price
														}{" "}
														€
													</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() =>
																removeAdditionItem(
																	item.name
																)
															}
														>
															<i class="material-icons">
																close
															</i>
														</IconButton>
													</TableCell>
												</TableRow>
											);
										}
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			) : null}

			<FormControlLabel
				control={
					<GreenSwitch
						checked={showSizes}
						onChange={handleSwitchSizes}
						name="sizes"
					/>
				}
				label="Velkosť"
			/>

			{showSizes ? (
				<div className="containerShadow">
					<div className="inputContainer">
						<CssTextField
							type="text"
							placeholder="Názov"
							onChange={(e) =>
								changeSizeName(
									e.target
										.value
								)
							}
							value={sizeName}
						/>
						<CssTextField
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										€
									</InputAdornment>
								),
							}}
							type="text"
							placeholder="Cena"
							onChange={(e) =>
								changeSizePrice(
									e.target
										.value
								)
							}
							value={sizePrice}
						/>
						<IconButton
							type="button"
							className="swal2-button buttonAdd"
							onClick={addSize}
						>
							<i className="material-icons">
								add
							</i>
						</IconButton>
					</div>

					{sizes.length == 0 ? null : (
						<TableContainer
							className="freeItemsAdded"
							component={Paper}
							className="tableDiv"
						>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>
											<b>
												Velkosť
											</b>
										</TableCell>
										<TableCell>
											<b>
												Cena
											</b>
										</TableCell>
										<TableCell align="right">
											<b>
												Odstrániť
											</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sizes.map(
										(
											item
										) => {
											return (
												<TableRow>
													<TableCell>
														{
															item.name
														}
													</TableCell>
													<TableCell>
																	{"+"}{
															item.price
														}{" "}
														€
													</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() =>
																removeSizesItem(
																	item.name
																)
															}
														>
															<i class="material-icons">
																close
															</i>
														</IconButton>
													</TableCell>
												</TableRow>
											);
										}
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			) : null}

			<div className="input-div-modal">
				<FormControlLabel
					control={
						<GreenCheckbox
							checked={available}
							onChange={(e) =>
								changeAvailable(
									e.target
										.checked
								)
							}
							name="checkedG"
						/>
					}
					label="Dostupné"
				/>
			</div>
		</div>
	);
};

export default EditProduct;
