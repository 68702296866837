import { Box, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";

const LastOrders = (props) => {
    const [numRows, setNumRows] = useState(0);
		const [t, i18n] = useTranslation('common')		

		let orders = [...props.orders].reverse()		

    return(
        <Card
        classes={{
            root:'dashboardItem'
        }}>
            <Box margin={3}>
                <CardHeader title={<h4>{t('dashboard.latest_orders')}</h4>} />
                <CardContent>
												{orders.length == 0 ? 
																			<Alert severity="info">{t('dashboard.no_orders')}</Alert>
																			:
																
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.number')}</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.name')}</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.email')}</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.delivery')}</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.price')}</TableCell>
                                    <TableCell style={{fontWeight:'bold'}}>{t('dashboard.time')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map(item => {
                                        
                                        return(
                                            <TableRow>
                                                <TableCell>#{item.orderID}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.delivery == 1 ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                                <TableCell>{item.price} €</TableCell>
                                                <TableCell>{item.date}</TableCell>
                                            </TableRow>
                                        )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
												}
                </CardContent>
            </Box>
        </Card>
    )
}
export default LastOrders;
