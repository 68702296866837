import './sofer.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from 'axios';
import Note from '../objednavky/note';
import LoaderSpinner from '../loader';
import H from "@here/maps-api-for-javascript";
import foodia_background_logo from './foodiadžsede.svg';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {ENDPOINT} from '../../GlobalComponents';


const MySwal = withReactContent(Swal);

const transport = axios.create({
    withCredentials: true
});



export default class OrderItem extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            customerLocation:undefined,
            isMounted:true
        }

        this.navigate = this.navigate.bind(this);
        this.getAddressLocation = this.getAddressLocation.bind(this);
        this.completeOrder = this.completeOrder.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.showMap = this.showMap.bind(this);
    }

    getAddressLocation(){
        let coors = [];

        const platform = new H.service.Platform({
            apikey: "pXqouIGUFQ97YbLNAM5o6dxosSeAvz4GLqFZTa7aW5g"
        });

        var service = platform.getSearchService();

        service.geocode({
            q: this.props.order.address
        }, (result) => {
          
            if(result.items[0] == undefined){
                return 'Test'
            }else{

                this.setState({
                    customerLocation:{
                        lat:result.items[0].position.lat.toString(),
                        lng:result.items[0].position.lng.toString()
                    }
                })

                //coors = result.items[0].position.lat.toString() + "," + result.items[0].position.lng.toString(); 
                return coors;
            }
            
        });
    }
    componentDidMount(){
        this.getAddressLocation();
    }

    navigate(){
       
        if /* if we're on iOS, open in Apple Maps */
          ((navigator.platform.indexOf("iPhone") != -1) || 
           (navigator.platform.indexOf("iPad") != -1) || 
           (navigator.platform.indexOf("iPod") != -1))
          window.open("maps://maps.google.com/maps?daddr="+this.state.customerLocation.lat+","+this.state.customerLocation.lng+"&amp;ll=");else /* else use Google */
          window.open("https://maps.google.com/maps?daddr="+this.state.customerLocation.lat +","+this.state.customerLocation.lng+"&amp;ll="); 
    }

    completeOrder(){
        this.props.setLoading();
        let payload = JSON.stringify({
            orderId:this.props.order.orderID,
            status:'complete',
            email:this.props.order.email
        });

        transport.patch(ENDPOINT + "/orders", payload)
            .then(res => {
                this.props.showAlert('success', 'Uložené');
                this.props.reload();
            }).catch(err => {
                this.props.showAlert('error', 'Chyba servera');
                this.props.reload();
            })
        
    }

    showDetails(){
        MySwal.fire({
            html:(
                <div id='detailsDiv'>
                    <div className='detailDiv'>
                        <i class="material-icons">note</i>
                        <p>{this.props.order.note}</p>
                    </div>
                    <div className='detailDiv'>
                        <i class="material-icons">mail</i>
                        <p>{this.props.order.email}</p>
                    </div>
                    <div className='detailDiv'>
                        <i class="material-icons">call</i>
                        <p>{this.props.order.phone_num}</p>
                    </div>
                    {this.props.order.delivery == 1 ?
                    <div className='detailDiv'>
                        <i class="material-icons">location_on</i>
                        <p>{this.props.order.address}</p>
                    </div>
                    :
                    null
                    }
                </div>
            ),
            showCloseButton:true,
            showConfirmButton:false
        });
    }


    showMap(){

        this.props.setLoading();
        
        transport.get("https://api.foodia.sk/orders/update.php?id=" + this.props.order.orderID + "&action=showMap")
            .then(res => {
                this.props.reload();
            })
    }
    


    render(){

        if(this.state.isMounted){
            return(
                <div className='driverNote'>
                    <div className='driverHeader'>
                        <a href={'tel:'+this.props.order.phone_num}><i className="material-icons">call</i></a>
                        <p onClick={this.showDetails}>#{this.props.order.orderID}</p>
                        <a href={'sms:' + this.props.order.phone_num} ><i className="material-icons">chat</i></a>
                    </div>
                    <hr />
                    <div className='driverBody'>
                        <p>{this.props.order.address}</p>
                        <div className='btns'>
                            <button type='button' onClick={this.navigate} className='driverBtn'><i className="material-icons">navigation</i></button>
                            <button type='button' onClick={this.completeOrder} className='driverBtn'><i className="material-icons">check</i></button>
                        </div>
                    </div>
                </div>
            )
        }else{
            return null;
        }
    }
}
