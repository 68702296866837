import './Customers.scss';
import React, { useState, useEffect } from 'react';

import { ENDPOINT } from '../../GlobalComponents.js';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { Alert, AlertTitle } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { DataGrid } from '@material-ui/data-grid';

import LoaderSpinner from '../loader';

const req = axios.create({
    withCredentials: true
});


const columns = [
  { id: 'name', label: 'Meno', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'phone_num', label: 'Tel. Číslo', minWidth: 170 }
];

function Customers(){
				const [loading, setLoading] = useState(true)
				const [newPostModalOpen, setNewPostModalOpen] = useState(false)
				const [processingRequest, setProcessingRequest] = useState(false)
				const [customers, setCustomers] = useState()

				const [earlyAccess, setEarlyAccess] = useState(false)
				const [page, setPage] = React.useState(0);
				const [rowsPerPage, setRowsPerPage] = React.useState(10);


				useEffect(() => {
								fetchData()
				}, [])

				const fetchData = () => {
								req.get(ENDPOINT + '/customers')
									.then(res => {
													console.log(res.data.data)
													setCustomers(res.data.data.data)
													setEarlyAccess(res.data.data.data.cust_id == '14' ? true : false)
													setLoading(false)
													console.log(res.data.data)
									}).catch(err => {
													console.log(err)
									})
				}

				const handleChangePage = (event, newPage) => {
						setPage(newPage);
				};

				const handleChangeRowsPerPage = (event) => {
								console.log('ss')
						setRowsPerPage(+event.target.value);
						setPage(0);
				};


				if(loading) return <LoaderSpinner />

				if(!earlyAccess) return (
								<>
												<Container maxWidth="lg" style={{marginTop:'3em'}}>
															<Alert severity="warning">
																				<AlertTitle>Pozor!
																				</AlertTitle>
																				Táto sekcia je momentálne v beta teste — <strong>ešte nie je dokončená.</strong> Čoskoro tu budete môcť jednoducho odosielať marketingové emaili vašim zákazníkom.
															</Alert>
																<Button style={{marginTop:'1em'}} color='primary' onClick={() => setEarlyAccess(true)}>Nahliadnuť</Button>
												</Container>
								</>


				)	

				return(
								<>
												<Container maxWidth="lg" style={{marginTop:'4em'}}>
																<h3>Zákazníci</h3>
																<Divider />

																<TableContainer style={{backgroundColor:'white', marginTop:'1em'}}>
																<Table stickyHeader aria-label="sticky table">
																	<TableHead>
																		<TableRow>
																			{columns.map((column) => (
																				<TableCell
																					key={column.id}
																					align={column.align}
																					style={{ minWidth: column.minWidth }}
																				>
																					{column.label}
																				</TableCell>
																			))}
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{customers.map((row) => {
																			return (
																				<TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
																					{columns.map((column) => {
																						const value = row[column.id];
																						return (
																							<TableCell key={column.id} align={column.align}>
																								{column.format && typeof value === 'number' ? column.format(value) : value}
																							</TableCell>
																						);
																					})}
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>
												</Container>
								</>
				)
}

export default Customers 
