import { IconButton } from '@material-ui/core';
import {useState} from 'react'
import {useTranslation, withTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';    
import './index.css'

function ChangeLanguage(){
				const [lang, setLang] = useState('sk')
				const [t, i18n] = useTranslation('common');

				return(
								<>
												{i18n.language == 'en' ?

													<Tooltip title={<p className='tooltipText'>Prepnúť na slovenčinu</p>}>
															<IconButton variant='text' onClick={() => i18n.changeLanguage('sk')} style={{height:'50px', width:'50px', opacity:1}}>
													&#127480;&#127472;
												</IconButton>
									</Tooltip>

								: 
									 <Tooltip title={<p className='tooltipText'>Switch to English</p>}>
								<IconButton variant='text' onClick={() => i18n.changeLanguage('en')} style={{height:'50px', width:'50px', opacity:1}}>
												&#127468;&#127463;
                </IconButton>
									 </Tooltip>

								}

								</>


				)

}

export default ChangeLanguage
