import React from 'react';
import './settings.scss';

const AutoComplete = (props) => {
    if(props.sugg.length == 0){
        return null;
    }
    else{
        return(
            <div className='autocompleteDiv'>
                <ul className='autocompleteList'>
                    {props.sugg.map(item => {
                        return <li className='autocompleteItem' onClick={() => props.fillAddress(item)}>{item}</li>
                    })}
                </ul>
            </div>
        )
    }
}

export default AutoComplete;