import '../../App.scss';
import Checkbox from '@material-ui/core/Checkbox';
import {GreenCheckbox, CssTextField} from '../../GlobalComponents';
import { FormControlLabel } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import { transport } from '../../App.js'
import Alert from '@material-ui/lab/Alert';
import {ENDPOINT} from '../../GlobalComponents';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {useTranslation} from "react-i18next";

const ChangePassword = (props) => {
				const [loading, setLoading] = useState(false)
				const [email, setEmail] = useState('')
				const [emailErr, setEmailErr] = useState(false)
				const [passwordEmailSent, setPasswordEmailSent] = useState(false)

				const [alertOpen, setAlertOpen] = useState(false) 
				const [alertMsg, setAlertMsg] = useState('')

			  const [t, i18n] = useTranslation('common');

				const forgotPassword = () => {
						if(!email){
										setEmailErr(true)
										return
						}

						setLoading(true)

						let payload = {};
						payload.email = email;

						transport.post(ENDPOINT +  '/reset-password', payload)
									.then(res => {
													setLoading(false)
													setPasswordEmailSent(true)
									}).catch(err => {
												let msg = err.response.data.data
												setAlertMsg(msg)
												setAlertOpen(true)
												setLoading(false)
									})
					}

    return(
        <div className='loginMain' style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div id='error'>
              <p id='errText'>Test error</p>
            </div>
            <div className='headerLogin'>
            </div>
            {!passwordEmailSent ? 
            <div className='container loginForm'>
              <p>{t('login.forgot_password')}</p>
              <div>
											{alertOpen ? <Alert severity="error">{alertMsg}</Alert> : null}
                <div class="form-group">
												<TextField error={emailErr} label={t('login.email')} variant="outlined" color="primary" fullWidth onChange={(e) => setEmail(e.target.value)} margin="normal"/>
                </div>

											<Button onClick={forgotPassword} color="primary" variant="contained" fullWidth size="large" disabled={loading}>{t('login.request_password_reset')}</Button>
              </div>
					
            </div>
            :
                <div className='container loginForm'>
                    <p>{t('login.check_your_mailbox')}</p>
                </div>
            }


{/*
				<FormControl>
												<Select
													style={{marginTop:"1em"}}			
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={i18n.language}
													onChange={(e) => i18n.changeLanguage(e.target.value)}
													margin="normal"
												>
																<MenuItem value='sk'>&#127480;&#127472; Slovenčina</MenuItem>
													<MenuItem value='en'>&#127468;&#127463; English</MenuItem>
												</Select>
											</FormControl>
		*/}

          </div>
    )
}

export default ChangePassword;
