import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { createMuiTheme } from "@material-ui/core/styles";

//const ENDPOINT = "http://localhost:8080";
const ENDPOINT = 'https://api.foodia.sk';

const primaryColor = "#8cba51";

const theme = createMuiTheme({
	palette: {
		primary: {
			light:"#8cba51",			
			main: "#79a441",
			dark:"#6c923a",			
			contrastText: "white",
		},
		secondary: {
			main: "#d9d9d9",
			contrastText: "white",
		},
	},
	root: {
		"& label.Mui-focused": {
			color: primaryColor,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: primaryColor,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "red",
			},
			"&:hover fieldset": {
				borderColor: "yellow",
			},
			"&.Mui-focused fieldset": {
				borderColor: primaryColor,
			},
		},
	},
});


const GreenSwitch = withStyles({
	switchBase: {
		color: "#8cba51",
		"&$checked": {
			color: "#8cba51",
		},
		"&$checked + $track": {
			backgroundColor: "#8cba51",
		},
	},
	checked: {},
	track: {},
})(Switch);

const GreenCheckbox = withStyles({
	root: {
		color: "#8cba51",
		"&$checked": {
			color: "#8cba51",
		},
	},
	checked: {},
})((props) => <Checkbox color="#8cba51" {...props} />);

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: primaryColor,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: primaryColor,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "red",
			},
			"&:hover fieldset": {
				borderColor: "yellow",
			},
			"&.Mui-focused fieldset": {
				borderColor: primaryColor,
			},
		},
	},
})(TextField);

export { CssTextField, GreenCheckbox, GreenSwitch, theme, ENDPOINT };
