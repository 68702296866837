import '../App.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import Loader from "react-loader-spinner";


export default function LoaderSpinner(){
        return(
          <div className='loaderSpin'>
            <Loader
            type="ThreeDots"
            color="#8cba51"
            height={100}
            width={100}
            timeout={5000} //3 secs
          />
        </div>
        )
}