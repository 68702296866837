import './SocialMedia.scss';
import React, { useState, useEffect } from 'react';

import { ENDPOINT } from '../../GlobalComponents.js';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { Alert, AlertTitle } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';

import LoaderSpinner from '../loader';

const req = axios.create({
    withCredentials: true
});

function SocialMedia(){
				const [loading, setLoading] = useState(true)
				const [newPostModalOpen, setNewPostModalOpen] = useState(false)
				const [processingRequest, setProcessingRequest] = useState(false)
				const [postContent, setPostContent] = useState(`Tešíme sa na vaše objednávky!

---
#restauracia #rozvozjedla #dennemenu #dovoz #jedlo #obed`)
				const [postImage, setPostImage] = useState(null)
				const [facebook, setFacebook] = useState(true)
				const [instagram, setInstagram] = useState(false)
				const [settings, setSettings] = useState()
				const [file, setFile] = useState()
				const [postImageFilename, setPostImageFilename] = useState()
				const [igDisabled, setIgDisabled] = useState(true)

				const [earlyAccess, setEarlyAccess] = useState(false)

				const [postContentError, setPostContentError] = useState(false)

				useEffect(() => {
								fetchData()
				}, [])

				const fetchData = () => {
								req.get(ENDPOINT + '/settings')
									.then(res => {
													console.log(res.data.data)
													setSettings(res.data.data.payload)
													setEarlyAccess(true)
													//setEarlyAccess(res.data.data.payload.cust_id == '14' ? true : false)
													let params = window.location.hash.split('?');
													if(params[1] == 'newPost=true'){
																	setNewPostModalOpen(true)
													}
													setLoading(false)
									}).catch(err => {
													console.log(err)
									})
				}


				const submitPost = () => {
								setProcessingRequest(true)

								const formData = new FormData()

								formData.append('message', postContent)
								formData.append('postImage', postImage)

								if(postImage && facebook){
												formData.append('facebook_image', facebook);
								}else if(facebook){
												formData.append('facebook_link', facebook);
								}

								if(instagram){
												formData.append('instagram', true);
								}

								req.post(ENDPOINT + '/social-media', formData)
									.then(res => {
													console.log(res.data);

													alert('Úspešne nahrané na sociálne siete!')

													setPostContent('')
													setFile('')
													setProcessingRequest(false)
													setNewPostModalOpen(false)
									}).catch(err => {
													alert(err)

													setProcessingRequest(false)
									})

				}

				const handleFileChange = (event) => {
							setPostImage(event.target.files[0])	
							setFile(URL.createObjectURL(event.target.files[0]));

							if(event.target.files[0]){
											setIgDisabled(false)
							}
				}

				const removeImg = () => {
								setPostImage(null)
								setFile(null)
								setPostImageFilename('')
								setIgDisabled(true)
								setInstagram(false)
				}

				if(loading) return <LoaderSpinner />

				if(!earlyAccess) return (
								<>
												<Container maxWidth="lg" style={{marginTop:'4em'}}>
															<Alert severity="warning">
																				<AlertTitle>Pozor!
																				</AlertTitle>
																				Táto sekcia je momentálne v beta teste — <strong>ešte nie je dokončená.</strong> Čoskoro tu budete môcť jednoducho pridávať príspevky na všetky vaše sociálne siete jedným klikom.
															</Alert>
																<Button style={{marginTop:'1em'}} color='primary' onClick={() => setEarlyAccess(true)}>Nahliadnuť</Button>
												</Container>
								</>


				)	

				return(
								<>
												<Container maxWidth="lg" style={{marginTop:'3em'}}>
																<h3>Sociálne Siete</h3>
																<Divider />

																{
																				(!settings.facebook_page_id || !settings.facebook_page_token) ? 

																								<Alert severity="warning" style={{marginTop:'1em'}}>
																								<AlertTitle>Pozor!
																								</AlertTitle>
																				Ešte ste neprepojili vášu facebookovú stránku v nastaveniach. Kliknite v ľavom menu na nastavenia, a pripojte vašu facebookovú stránku.	
																</Alert>
																: null
																}
																<Button variant="contained" color="primary" style={{marginTop:'1em'}} onClick={(e) => setNewPostModalOpen(true)} disabled={(!settings.facebook_page_id || !settings.facebook_page_token)}>Nový príspevok</Button>

												</Container>

												 <Dialog
												open={newPostModalOpen}
												onClose={(e) => setNewPostModalOpen(!newPostModalOpen)}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
													>
												<DialogTitle id="alert-dialog-title">Nový príspevok</DialogTitle>
												<DialogContent>
													<DialogContentText id="alert-dialog-description">
																	Pridajte nový príspevok na vaše sociálne siete (Facebook, Instagram). K príspevku bez obrázku sa automaticky pridá link na vašu objednávaciú stránku aj s grafikou. Pozor! Na instagram nie je možné nahrať príspevok bez fotky.
													</DialogContentText>
																				<TextField label="Obsah príspevku..." variant="filled" 
																					value={postContent}
																					onChange={(e) => setPostContent(e.target.value)}			
																					error={postContentError}
																								fullWidth
																								multiline
																				/>

																 <input
																accept=".png, .jpeg, .jpg"
																onChange={handleFileChange}				 
																type="file"
																style={{marginTop:'1em'}}				 
																value={postImageFilename}				 

															/>
																{/* Preview of a file */ }

																{file && <div className="filePreview" onClick={removeImg}><img src={file} style={{maxWidth:'100%', marginTop:'1em'}} /></div> }

																<FormGroup row style={{marginTop:'0.5em'}}>

																 <FormControlLabel
																control={
																	<Checkbox
																		checked={instagram}
																		onChange={() => setInstagram(!instagram)}
																		color="primary"
																		disabled={igDisabled}			
																	/>
																}
																label="Instagram"
															/>
																 <FormControlLabel
																control={
																	<Checkbox
																		checked={facebook}
																		onChange={() => setFacebook(!facebook)}
																		color="primary"
																	/>
																}
																label="Facebook"
															/>
																</FormGroup>

												</DialogContent>
												<DialogActions>
													<Button onClick={(e) => setNewPostModalOpen(false)} color="primary">
																Zrušiť
													</Button>
																<Button color="primary" autoFocus variant="contained" disabled={processingRequest} onClick={submitPost}>
																Nahrať
																	
													</Button>
												</DialogActions>
											</Dialog>

								</>
				)
}

export default SocialMedia
