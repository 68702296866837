import './dashboard.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import LoaderSpinner from '../loader';
import axios from 'axios';
import foodia_background_logo from '../menu/foodiadžsede.svg';
import ChartPie from './PieChart';
import {ENDPOINT} from '../../GlobalComponents';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import { PowerSettingsNew, PowerSettingsNewOutlined } from '@material-ui/icons';
import {useTranslation, withTranslation} from "react-i18next";

const transport = axios.create({
    withCredentials: true
});

class OnlineOrdering extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            labels:['nové', 'v kuchyni','na ceste'],
            datasets:[{
                data:this.props.orderData,
                backgroundColor:['#dc3545', '#ffc107', '#28a745']
            }]
        }

        this.switchSystem = this.switchSystem.bind(this);

    }
    
    switchSystem(val){

        this.setState({
            loading:true
        });

        let payload = JSON.stringify({
            running:val
        });

        transport.post(ENDPOINT + '/settings', payload)
            .then(res => {
                console.log(res.data);
                this.setState({
                    loading:false
                });

                this.props.showAlert('success', 'Uložené');
                this.props.loadSettings();

										if(val && this.props.settings.facebook_page_token && this.props.settings.facebook_page_id){
														window.location.href='#/social-media?newPost=true'
										}
            })

    }



    render(){

				const { t } = this.props;

        if(this.props.settings.running == 1){
            return(
                <Card classes={{
                    root:'dashboardItem'
                }}>
                    <Box margin={3}>
                    
                        <CardHeader subheader={<a href={this.props.settings.foodia_url} target="_blank" class='link'>{this.props.settings.foodia_url}</a>} title={<h4>{t('dashboard.ordering_is')} <span id='systemState' className='on'>{t('dashboard.enabled')}</span></h4>} />
                        <CardContent>
                                <CardActions disableSpacing={true} style={{textAlign:'center'}}>
                                <Button startIcon={<PowerSettingsNew />} id='btnDefault' onClick={() => this.switchSystem(0)} classes={{
                                    label:'btnLabel',
                                    root:'offBtn'
                                }} variant='contained' fullWidth={true}>{t('dashboard.disable')}</Button>
                                </CardActions>
                        </CardContent>
                    </Box>
                </Card>
            )
        }else{
            return(
                <Card classes={{
                    root:'dashboardItem'
                }}>   

                <Box margin={3}>  

                        <CardHeader subheader={t('dashboard.customers_cannot_order')} title={<h4>{t('dashboard.ordering_is')} <span id='systemState' className='off'>{t('dashboard.disabled')}</span></h4>} />
                        <CardContent>
                            <CardActions disableSpacing={true} style={{textAlign:'center'}} >
                            <Button startIcon={<PowerSettingsNewOutlined /> } classes={{
                                root:'backgroundGreen',
                                label:'btnLabel'
                            }} fullWidth={true} variant='contained' color='primary' id='btnDefault' onClick={() => this.switchSystem(1)}>{t('dashboard.enable')}</Button>
                            </CardActions>
                        </CardContent>
                </Box>
                </Card>
            )

        }
    }
}

const OnlineOrderingTranslated = withTranslation('common')(OnlineOrdering)
export default OnlineOrderingTranslated
