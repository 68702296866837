import './objednavky.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from 'axios';
import Note from './note';
import LoaderSpinner from '../loader';
import foodia_background_logo from './foodiadžsede.svg';
import alertAudio from '../../assets/alert.mp3';
import Push from 'push.js';
import {ENDPOINT} from '../../GlobalComponents';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import {useTranslation, withTranslation} from "react-i18next";

const MySwal = withReactContent(Swal);

const transport = axios.create({
    withCredentials: true
});

class Objednavky extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            orders:null,
            isLoading:false,
            filter:'',
            interval:null,
            drivers:[],
            new:0,
            inKitchen:0,
            onWay:0
        }

        //this.checkNewOrders = this.checkNewOrders.bind(this);
        this.filter = this.filter.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.playAudio = this.playAudio.bind(this);
        this.getDrivers = this.getDrivers.bind(this);
        this.loadOrders = this.loadOrders.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount(){
    }

    loadOrders(){

        this.setState({
            new:0,
            inKitchen:0,
            onWay:0
        });
    
        transport.get(ENDPOINT + "/orders")
        .then(res => {
    
            for(let i in res.data.data.data){
                switch(res.data.data.data[i].status){
                    case 'new':
                        this.setState({
                            new:this.state.new + 1
                        });
                        break;
                    case 'inKitchen':
                        this.setState({
                            inKitchen:this.state.inKitchen + 1
                        });
                        break;
                    case 'onWay':
                        this.setState({
                            onWay:this.state.onWay + 1
                        });
                        break;
                    default:
                        console.log('default');
                        break;
                }
            }
            this.setState({
              isLoading:false,
              orders:res.data.data.data
            })
            
        });
        
    }

      
    updateStatus(action, id, email){
												this.setState({
																				isLoading:true
												})

        let payload = JSON.stringify({
            orderId:id,
            status:action,
            email:email
        });

        transport.patch(ENDPOINT + "/orders", payload)
            .then(res => {
                this.props.showAlert('success', 'Uložené');
                this.props.loadOrders();
                
            }).catch(err => {
                this.props.showAlert('error', 'Chyba servera');
                this.props.loadOrders();
            })
    }

    setLoading(){
        this.setState({
            isLoading:true
        })
    }

    getDrivers(){
        transport.get("https://api.foodia.sk/location/getAllDrivers.php")
        .then(res => {
            this.setState({
                drivers:res.data
            })
        })
    }  

    /*
    checkNewOrders(){
        let prevOrders = this.state.new + this.state.inKitchen + this.state.onWay;

        transport.get("https://api.foodia.sk/orders/read.php?status=all")
        .then(res => {
            this.setState({
                new:res.data.new.length,
                inKitchen:res.data.inKitchen.length,
                onWay:res.data.onWay.length,
                orders:res.data.new.concat(res.data.inKitchen, res.data.onWay)
            }, () => {
                let nextOrders = this.state.inKitchen + this.state.onWay + this.state.new;

                if(prevOrders < nextOrders){

                    this.playAudio();

                    Push.create('Nová objednávka !');

                    MySwal.fire({
                        title: 'Nová objednávka !',
                        showConfirmButton:false,
                        customClass:{
                            popup:'newOrderPopup',
                            title:'newOrderTitle',
                            container:'newOrderPopup'
                        },
                        timer:5000
                    })
                }
            })
        }).catch(err => {
            alert(err);
        })
    }
    */

    playAudio(){
        var audio = new Audio(alertAudio);
        audio.play();
    }

    filter(status){
        this.setState({
            filter:status
        })
    }

    render(){
						const { t } = this.props;

        if(this.state.isLoading){
            return <LoaderSpinner />
        }else{
        return(
            <div>
                <img src={foodia_background_logo} className='logoMiddle' /> 
                <div id='mainObjednavky'>
                    <div id='indicators'>
                        {this.state.filter !== '' ? <span className='filterBtn indicator remove' onClick={() => this.filter('')}>{t('orders_section.remove_filters')}</span> : null}
                        <p><span className="badge badge-pill badge-danger indicator filterBtn" onClick={() => this.filter('new')} >{t('orders_section.new')} <span className="badge badge-light">{this.props.new}</span></span></p>
                        <p><span className="badge badge-pill badge-warning indicator filterBtn" onClick={() => this.filter('inKitchen')}>{t('orders_section.in_kitchen')} <span className="badge badge-light">{this.props.inKitchen}</span></span></p>
                        <p><span className="badge badge-pill badge-success indicator filterBtn" onClick={() => this.filter('onWay')}>{t('orders_section.on_way')} <span className="badge badge-light">{this.props.onWay}</span></span></p>
                    </div>
                  <hr />
                    <div id='ordersWrapper'>
                        <div id='orders'>
                        {this.props.orders.map((order, index) => {

                            if(order.status !== 'complete'){
                                if(this.state.filter == ''){
                                    return <Note order={order}  content={JSON.parse(order.content)} reload={this.loadOrders} drivers={this.state.drivers} showAlert={this.props.showAlert} updateStatus={this.updateStatus} />
                                }else if(this.state.filter == 'new' && order.status == 'new'){
                                        return <Note order={order}  content={JSON.parse(order.content)} reload={this.loadOrders} drivers={this.state.drivers} showAlert={this.props.showAlert} updateStatus={this.updateStatus} />
                                }else if(this.state.filter =='inKitchen' && order.status == 'inKitchen'){
                                    return <Note order={order}  content={JSON.parse(order.content)} reload={this.loadOrders} drivers={this.state.drivers} showAlert={this.props.showAlert} updateStatus={this.updateStatus} />
                                }else if(this.state.filter == 'onWay' && order.status == 'onWay'){
                                    return <Note order={order}  content={JSON.parse(order.content)} reload={this.loadOrders} drivers={this.state.drivers} showAlert={this.props.showAlert} updateStatus={this.updateStatus} />
                                }
                            }else{
                                return null;
                            }
                        })
                    }
                        </div>
                    </div>
                </div>
            </div>
        )
               }
    }
}


const ObjednavkyTranslated = withTranslation('common')(Objednavky)
export default ObjednavkyTranslated 
