import React, {useState} from 'react';
import './settings.scss';
import axios from 'axios';
import LoaderSpinner from '../loader';
import Tooltip from '@material-ui/core/Tooltip';    

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import {CssTextField, GreenSwitch, ENDPOINT} from '../../GlobalComponents';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Add, ArrowDownward, Delete, ExpandLess, ExpandMore, FavoriteBorderRounded, HelpOutline, HelpOutlined, LocationOn, Save } from '@material-ui/icons';
import { Collapse, Fade, IconButton, Input, Link, Slide } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import H from "@here/maps-api-for-javascript";
import AutoComplete from './Autocomplete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputLabel from '@material-ui/core/InputLabel';
import FacebookProvider, { Login } from 'react-facebook-sdk';
import FormControl from '@material-ui/core/FormControl';


const primaryColor = '#8cba51';

const MySwal = withReactContent(Swal);

const transport = axios.create({
    withCredentials: true
});

export default class Settings extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading:true,
            name:'',
            email:'',
            telNum:'',
            webpage:'',
            facebook:'',
            instagram:'',
            color:'',
            description:'',
            openingHours:'',
            driverPhone:'',
            foodia_url:'',
            main_section:'',
            location:'',
            changeLogo:null,
            restaurantLogo:[],
            to_pay:0,
            deliveryFee:'',
            delivery:false,
            pickup:true,
            onlinePayment:false,
            tableService:false,
            drivers:[],
            suggestions:[],
            orderTracking:false,
            relativeValues:{
                driver_name:'',
                subdomain:''
            },
            filesOpened:false,
            emailsOpened:false,
            saveAlertShown:false,
            locationOpened:false,
            errorAlertShown:false,
            errorAlertText:'',
            successAlertShown:false,
            successAlertText:'',
            redirect:false,
            redirectUrl:'',
            tableService:false,
						locationInput:'',
						fbPages:null,
						selectedFbPage:'default'		
        }

        this.getAllDrivers = this.getAllDrivers.bind(this);
        this.addDriver = this.addDriver.bind(this);
        this.loadSettings = this.loadSettings.bind(this);
        this.setDriverName = this.setDriverName.bind(this);
        this.setDomain = this.setDomain.bind(this);
        this.createSubdomain = this.createSubdomain.bind(this);
        this.fileInput = this.fileInput.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.changeLogo = this.changeLogo.bind(this);
        this.pickAddress = this.pickAddress.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.updateSetting = this.updateSetting.bind(this);
				this.connectFacebook = this.connectFacebook.bind(this);		
				this.handleErrFb = this.handleErrFb.bind(this);		
				this.changeFbPage = this.changeFbPage.bind(this);		
    }
				



    componentDidMount(){
        this.loadSettings();
    }

    setDomain(val){
        this.setState({
            relativeValues:{
                subdomain:val
            }
        })
    }

		changeFbPage(page){
						console.log(page)

						this.setState({
										selectedFbPage:page
						})

						this.updateSetting('facebook_page_id', page);
		}

    createSubdomain(){


        MySwal.fire({
            titleText:'Nastavenie domény',
            showCloseButton:true,
            html:<NewSubdomain setDomain={this.setDomain} />,
            showConfirmButton:true,
            confirmButtonText:'Vytvoriť',
            heightAuto:false
        }).then((result) => {

            if(result.isConfirmed){

                if(this.state.relativeValues.subdomain.length == 0){
                    MySwal.fire({
                        titleText:"Nesprávny formát",
                        icon:"error",
                        heightAuto:false
                    })

                    return false;
                }


                this.setState({
                    isLoading:true
                });

                let payload = JSON.stringify({
                    subdomain:this.state.relativeValues.subdomain
                });

                transport.post("https://api.foodia.sk/settings/createSubdomain.php", payload)
                    .then(res => {
                        this.loadSettings();
                    });
                
            }
        })

    }

    loadSettings(){
        transport.get(ENDPOINT + "/settings")
        .then(res => {
            this.setState({
                settings:res.data.data.payload,
								inputLocation:res.data.data.payload.location,		
                isLoading:false
            }, () => {
                console.log(this.state.settings);

            })
        }).catch(err => {
            this.props.showAlert('error', 'Chyba');
						this.setState({
										isLoading:false
						})		
        })
    }

    getAllDrivers(){
        transport.get("https://api.foodia.sk/location/getAllDrivers.php")
        .then(res => {
            this.setState({
                drivers:res.data,
                isLoading:false
            })
        })
    }

    updateSetting(name, new_val){

        let payload = {};
        payload[name] = new_val;

        transport.post(ENDPOINT + '/settings', JSON.stringify(payload))
            .then(res => {
                this.props.showAlert('success', 'Uložené');
                this.loadSettings();
            }).catch(err => {
                this.props.showAlert('error', 'Chyba');
            })
    }


    setDriverName(val){
        this.setState({
            relativeValues:{
                driver_name:val
            }
        })
    }

    addDriver(){

        MySwal.fire({
            titleText:'Nový šofér',
            showCloseButton:true,
            html:<NewDriver setName={this.setDriverName} />,
            showConfirmButton:true,
            confirmButtonText:'Vytvoriť',
            heightAuto:false
        }).then((result) => {

            if(result.isConfirmed){

                if(this.state.relativeValues.driver_name.length == 0){
                    MySwal.fire({
                        titleText:"Nesprávný formát",
                        icon:"error"
                    })

                    return false;
                }

                this.setState({
                    isLoading:true
                });

                let payload = JSON.stringify({
                    name:this.state.relativeValues.driver_name
                });

                transport.post("https://api.foodia.sk/location/createDriver.php", payload)
                    .then(res => {
                        this.loadSettings();
                    });
            }
        })
    }

    deleteDriver(name){
        this.setState({
            isLoading:true
        });

        let payload = JSON.stringify({
            name:name
        });

        transport.post("https://api.foodia.sk/location/deleteDriver.php", payload)
            .then(res => {
                console.log(res);

                this.loadSettings();
            });
    }

    // 1) Save image file to state variable restaurantLogo onChange

    fileInput(e){

        this.setState({
            restaurantLogo:[e.target.name,e.target.files[0]]
        }, () => {
            this.uploadLogo();
            console.log(this.state.restaurantLogo);
        });
    }

    // 2) Construct formdata and upload the logo once user clicks submit

    uploadLogo(){
        
        let formData = new FormData();
        formData.append(this.state.restaurantLogo[0], this.state.restaurantLogo[1]);

        transport({
            method: "post",
            url: ENDPOINT + "/files",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            if(res.data.data != 'Success'){
                this.props.showAlert('error', 'Chyba');
            }else{
                this.props.showAlert('success', 'Uložené');
                this.loadSettings();
            }
            
        })
    }

    changeLogo(){
        this.setState({changeLogo:true});
    }

    onChangeAddress(e){

        this.setState({
								inputLocation:e.target.value
        });

						if(e.target.value == ""){
										this.updateSetting('location', '')
						}

        let rawString = e.target.value;
        let queryString = rawString.replace(/\s/g, '+');
        
        if(rawString.length > 0){

            transport.get('https://autocomplete.search.hereapi.com/v1/autosuggest?at=48.624242268361655,18.37476400310695&q='+ queryString +'&apiKey=RSFAxpfJ0oWl9r90ZcgLAqAfz9so42g1WwZc6zkQKJM').
                then(res => {

                    let newArr = res.data.items.map(item => {
                        for(let i in item.address){
                            return item.address[i];
                        }
                    })

                    this.setState({
                        suggestions:newArr
                    });
                }).catch(err => err);
        }else{
            this.setState({
                suggestions:[]
            })
            return false;
        }
    }

        
    pickAddress(address){
        this.setState({
								inputLocation:address
            
        })

        this.setState({
            suggestions:[]
        })

        this.updateSetting('location', address);
    }

    setStateVal(key,val){
        this.setState({
            settings:{
                ...this.state.settings,
                key:val
            }
        })
    }

		connectFacebook(res){

						this.setState({
										isLoading:true
						})

						let payload = {
										token:res.tokenDetail.accessToken,
										fb_user_id:res.profile.id,
										type:'fb'
						}

						transport.post(ENDPOINT + '/integrations', JSON.stringify(payload))
										.then(res => {
														this.loadSettings()
														this.props.showAlert('success', 'Facebook účet bol pripojený');
										}).catch(e => {
														this.props.showAlert('error', 'Facebook účet nebol pripojený');
										})

		}

		handleErrFb(){
						alert('error')
		}


    render(){
        if(this.state.isLoading){
            return <LoaderSpinner />
        }else{
            return(
                <div className='settingsOuter'>

                    <div className='settingsMain'>
                        <div className='settingsSection'>
                            <p className='settingsHeading'>Účet</p>
                            <ul>
                                <li>
                                    <p>Odhadovaný poplatok<ToolTipCustom text='Poplatok sa počíta na základe tržby.' /></p>
                                    <p className='settingsLabel'>{this.state.settings.est_fee}{" €"}</p>
                                </li>
                                <li>
                                    <p>Prepojenie facebook účtu<ToolTipCustom text='Toto prepojenie vám umožňuje automaticky pridávať príspevky na facebook / instagram.' /></p>
																				<p className='settingsLabel'>
																								  <FacebookProvider appId="614159803826049">
																											<Login
																												scope="pages_show_list,
																																pages_manage_posts,
																																instagram_basic,
																																pages_read_engagement,
																																instagram_content_publish,
																																ads_management,
																																business_management"
																												onResponse={this.connectFacebook}
																												onError={this.handleErrFb}
																											>
																															<Button color="primary">
																																			{this.state.settings.facebook_token ? "✅ Pripojené" : "Pripojiť"}	
																															</Button>
																											</Login>
																										</FacebookProvider>
																				</p>
                                </li>


																		{/*
																		{this.state.fbPages ? 
																<li>
                                    <p>Facebooková stránka <ToolTipCustom text='Vyberte si facebookovú stránku ktorú chcete prepojiť.' /></p>
																				<p className='settingsLabel'>
																								 <Select
																									labelId="facebook-page-label"			 
																									value={this.state.selectedFbPage}
																									onChange={(e) => this.changeFbPage(e.target.value)}
																								>
																												 <MenuItem value="default" disabled>Vyberte si</MenuItem>
																												 {this.state.fbPages.map((item) => {
																																 return(
																																					<MenuItem value={item.access_token}>{item.name}</MenuItem>
																																 )
																												 })}
																								</Select>
																												
																				</p>
                                </li>
																: null}
																*/}
                            </ul>

                        </div>

                        <div className='settingsSection'>
                            <p className='settingsHeading'>Reštaurácia</p>
                            <ul>
                                <li>
                                    <p>Meno</p>
                                    <input type='text' value={this.state.settings.restaurant_name} onBlur={(e) => this.updateSetting('restaurant_name', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,restaurant_name:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Email</p>
                                    <input type='text' value={this.state.settings.restaurant_email} onBlur={(e) => this.updateSetting('restaurant_email', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,restaurant_email:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Tel. číslo</p>
                                    <input type='text' value={this.state.settings.restaurant_phone} onBlur={(e) => this.updateSetting('restaurant_phone', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,restaurant_phone:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Webstránka</p>
                                    <input type='text' value={this.state.settings.website_url} onBlur={(e) => this.updateSetting('website_url', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,website_url:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Instagram</p>
                                    <input type='text' value={this.state.settings.instagram_url} onBlur={(e) => this.updateSetting('instagram_url', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,instagram_url:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Facebook</p>
                                    <input type='text' value={this.state.settings.facebook_url} onBlur={(e) => this.updateSetting('facebook_url', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,facebook_url:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>

        
                                
                                <li className='settingsRow'>
                                 <p>Lokácia <ToolTipCustom text='Kde sa nachádza vaša reštaurácia?' /></p>
                                            <div className='autocompleteOuter'>
                                                <input type='text' className='autoCompleteInput' placeholder='Prázdne' id='locationInput' value={this.state.inputLocation} onChange={this.onChangeAddress} />
                                                <AutoComplete sugg={this.state.suggestions} fillAddress={this.pickAddress} />
                                            </div>
                             
                                </li>
                            </ul>

                        </div>
                        <div className='settingsSection'>
                            <p className='settingsHeading'>Online Objednávanie</p> 
																{
																				/*
                            {!this.state.settings.foodia_url ? 
                            <button className='btnStyles' onClick={this.createSubdomain}>Aktivovať</button>

                            :
														*/
																}
                            
                            <ul>
                                <li>
                                    <p>URL</p>
                                    <a href={this.state.settings.foodia_url} target='_blank' className='settingsLink'><i class="material-icons settingsIcon">open_in_new</i> {this.state.settings.foodia_url}</a>
                                </li>
                                <li>
                                    <p>QR Kód link <ToolTipCustom text='Po zoskenovaní QR kódu bude zákazník presmerovaný na objednávací systém.' /></p>
                                    <a href={'http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=https%3A%2F%2F' + this.state.settings.foodia_url.split("//")[1] + '&qzone=1&margin=0&size=400x400&ecc=L'} target='_blank' className='settingsLink' download><i class="material-icons settingsIcon">download</i>Stiahnuť</a>
                                </li>
                                <li>
                                    <p>Primárna farba</p>
                                    <input type='color' value={this.state.settings.primary_color} onBlur={(e) => this.updateSetting('primary_color', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,primary_color:e.target.value}})} className='settingsInput' />
                                </li>
                                <li>
                                    <p>Popis</p>
																				<textarea value={this.state.settings.restaurant_description} onBlur={(e) => this.updateSetting('restaurant_description', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,restaurant_description:e.target.value}})} className='settingsInput' style={{"textAlign":"left"}} placeholder='Prázdne' />
                                </li>
                                <li>
                                    <p>Otváracie hodiny <ToolTipCustom text='Otváracie hodiny oddelené čiarkou, napr. PO-PIA: 10:00 - 15:00, SO-NE: 9:00 - 12:00' /></p>
                                    <input type='text' value={this.state.settings.opening_hours} onBlur={(e) => this.updateSetting('opening_hours', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,opening_hours:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                </li>

                                <li>
                                    <p>Hlavná sekcia <ToolTipCustom text='Hlavná sekcia bude návštevníkom zobrazená ako prvá' /></p>
                                    {this.state.settings.sections  !== null && this.state.settings.sections.length !== 2 ?

                                    <select
                                        value={this.state.settings.main_section}
                                        onChange={(e) => this.updateSetting('main_section', e.target.value)}
                                        className='settingsSelect'
                                        >
                                        <option disabled>
                                            Vyberte jednu z možností
                                        </option>

                                        {JSON.parse(this.state.settings.sections).map(section => {

                                            if(section == this.state.settings.main_section){
                                                return(
                                                    <option value={section} selected>{section}</option>
                                                )
                                            }
                                            return(
                                                <option value={section}>{section}</option>
                                            )
                                        })}
                            
                                    </select>
                                    : <a href='#/menu' className='settingsLink'> Pridajte sekciu </a>
                                }
                                </li>

                                <li className='settingsRow'>                                    
                                    
                                    <p>Súbory <ToolTipCustom text='Prehliadač súborov pre logá..atd.' /></p>
                                    <IconButton onClick={() => this.setState({filesOpened:this.state.filesOpened ? false : true})}>{this.state.filesOpened ? <ExpandLess/> : <ExpandMore />}</IconButton>
                                
                                </li>

                                <Collapse in={this.state.filesOpened} classes={{container:'collapseEl'}}>
                                    <li className='settingsRow'>
                                        <p>Zmeniť logo</p>
                                       <input type='file' name='file' onChange={(e) => this.fileInput(e)} className='settingsUpload' /> 
                                    </li>
                                    {this.state.settings.restaurant_logo !== null ? 
                                    <li className='settingsRow'>
                                       <p>Aktuálne logo</p>
                                       <a target='_blank' href={'https://uploads.foodia.sk/logos/' + this.state.settings.logo_id}>{this.state.settings.restaurant_logo}</a>
                                    </li>
                                    : null}

								 	<li className='settingsRow'>                                    
										<p>Biele pozadie loga<ToolTipCustom text='Pridá bielu farbu na pozadie vášho loga.' /></p>
										<GreenSwitch checked={this.state.settings.logo_background == 1 ? true : false} onChange={(e) => this.updateSetting('logo_background', this.state.settings.logo_background == 1 ? 0 : 1 )} />
									</li>
                                </Collapse>

                                <li className='settingsRow'>                                    
                                    <p>Dovoz<ToolTipCustom text='Ponúka Vaša reštaurácia možnosť dovozu?' /></p>
                                    <GreenSwitch checked={this.state.settings.delivery == 1 ? true : false} onChange={(e) => this.updateSetting('delivery', this.state.settings.delivery == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.delivery == 1 ? true : false} unmountOnExit mountOnEnter>
                                    <li>                                    
                                        <p>Poplatok za dovoz <ToolTipCustom text='Táto suma sa automaticky pripočíta k objednávke ak si zákazník vyberie možnosť dovozu.' /></p>
                                        <input type='text' value={this.state.settings.delivery_fee} onBlur={(e) => this.updateSetting('delivery_fee', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,delivery_fee:e.target.value}})} className='settingsInput' placeholder='0.00' />
                                    </li>
                                </Collapse>

{/*
                                <li className='settingsRow'>                                    
                                    <p>Zóny dovozu<ToolTipCustom text='Definujte zóny dovozu s rôznymi poplatkami.' /></p>
                                    <GreenSwitch checked={this.state.settings.delivery_zones == 1 ? true : false} onChange={(e) => this.updateSetting('delivery_zones', this.state.settings.delivery_zones == 1 ? 0 : 1 )} />
</li>*/}

{/*
                                <Fade in={this.state.settings.delivery_zones == 1 ? true : false} unmountOnExit mountOnEnter>
                                    <li>                                    
                                        <p>Poplatok za dovoz <ToolTipCustom text='Táto suma sa automaticky pripočíta k objednávke ak si zákazník vyberie možnosť dovozu.' /></p>
                                        <input type='text' value={this.state.settings.delivery_fee} onBlur={(e) => this.updateSetting('delivery_fee', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,delivery_fee:e.target.value}})} className='settingsInput' placeholder='0.00' />
                                    </li>
</Fade>*/}

                                <li className='settingsRow'>                                    
                                    <p>Vyzdvihnutie<ToolTipCustom text='Vypnite/zapnite možnosť si vyzdvihnúť jedlo.' /></p>
                                    <GreenSwitch checked={this.state.settings.pickup == 1 ? true : false} onChange={(e) => this.updateSetting('pickup', this.state.settings.pickup == 1 ? 0 : 1 )} />
                                </li>
                                <li className='settingsRow'>                                    
                                    <p>Objednávky pri stôl<ToolTipCustom text='Umožnite zákazníkom objednať si priamo vo vašej reštaurácii. Pri objednávaní musia vyplniť číslo stolu ktoré by malo byť zobrazené/prilepené na stole.' /></p>
                                    <GreenSwitch checked={this.state.settings.table_service == 1 ? true : false} onChange={(e) => this.updateSetting('table_service', this.state.settings.table_service == 1 ? 0 : 1 )} />
                                </li>

                                <li className='settingsRow'>                                    
                                    <p>Presmerovanie<ToolTipCustom text='Zákazníci budú po objednaní presmerovaný na špecifikovanú webovú adresu.' /></p>
                                    <GreenSwitch checked={this.state.settings.redirect == 1 ? true : false} onChange={(e) => this.updateSetting('redirect', this.state.settings.redirect == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.redirect == 1 ? true : false} unmountOnExit mountOnEnter>
                                    <li>                                    
                                        <p>URL</p>
                                        <input type='text' value={this.state.settings.redirect_url} onBlur={(e) => this.updateSetting('redirect_url', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,redirect_url:e.target.value}})} className='settingsInput' placeholder='Prázdne' />
                                    </li>
                                </Collapse>

                                <li className='settingsRow'>                                    
                                    <p>Verejné recenzie<ToolTipCustom text='Zapnutie tejto možnosti zobrazí link k recenziám na vašej objednávacej webstránke.' /></p>
                                    <GreenSwitch checked={this.state.settings.reviews_public == 1 ? true : false} onChange={(e) => this.updateSetting('reviews_public', this.state.settings.reviews_public == 1 ? 0 : 1 )} />
                                </li>

{/*
                                <li className='settingsRow'>                                    
                                    <p>Sledovanie objednávok<ToolTipCustom text='Zákazníci budú môcť na samostatnej stránke sledovať stav objednávky (v kuchyni, na ceste, pripravená na vyzdvihnutie).' /></p>
                                    <GreenSwitch checked={this.state.settings.order_tracking == 1 ? true : false} onChange={(e) => this.updateSetting('order_tracking', this.state.settings.order_tracking == 1 ? 0 : 1 )} />
</li>*/}

{/*
                                <li className='settingsRow'>                                    
                                    <p>Platby online<ToolTipCustom text='Umožnite zákazníkom zaplatiť vopred online.' /></p>
                                    <GreenSwitch checked={this.state.settings.online_payment == 1 ? true : false} onChange={(e) => this.updateSetting('online_payment', this.state.settings.online_payment == 1 ? 0 : 1 )} />
</li>*/}

                               

                            </ul>
                        </div>

{/*
                        <div className='settingsSection'>
                            <p className='settingsHeading'>Šoféri</p>
                            <ul>

                                {this.state.drivers.map(driver => {
                                    return(
                                        <li>
                                            <p>{driver.driver_name}</p>
                                            <IconButton type='button' className='secondaryBtnStyles settingsIcon' onClick={() => this.deleteDriver(driver.driver_name)}><Delete /></IconButton>
                                        </li>
                                    )
                                })}

                                <IconButton type='button' className='secondaryBtnStyles settingsIcon' onClick={this.addDriver}><Add /> </IconButton>
                                
                            </ul>
                        </div>      
                            */}    

                        <div className='settingsSection'>
                            <p className='settingsHeading'>Notifikácie</p>
                            <ul>
                                <li className='settingRow'>
                                    <p>SMS Notifikácie</p>
                                    <GreenSwitch checked={this.state.settings.sms_notifications == 1 ? true : false} onChange={(e) => this.updateSetting('sms_notifications', this.state.settings.sms_notifications == 1 ? 0 : 1 )} />
                                </li>
                                <li className='settingRow'>
                                    <p>Telefónne notifikácie</p>
                                    <GreenSwitch checked={this.state.settings.call_notifications == 1 ? true : false} onChange={(e) => this.updateSetting('call_notifications', this.state.settings.call_notifications == 1 ? 0 : 1 )} />
                                </li>
																																				{this.state.settings.sms_notifications == 1 || this.state.settings.call_notifications == 1 ? 
																																				<li className='settingRow'>
																																												<p>Kam odosielať SMS / Telefónne notifikácie?</p>
																																												<input type='text' value={this.state.settings.notification_phone_num} onBlur={(e) => this.updateSetting('notification_phone_num', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings, notification_phone_num:e.target.value}})} className="settingsInput" placeholder='0903 123 456' />
																																				</li>
																																				: null }
                                <li className='settingRow'>
                                    <p>Email Notifikácie</p>
                                    <GreenSwitch checked={this.state.settings.email_notifications == 1 ? true : false} onChange={(e) => this.updateSetting('email_notifications', this.state.settings.email_notifications == 1 ? 0 : 1 )} />
                                </li>
                            </ul>
                        </div>


                        <div className='settingsSection'>
                            <p className='settingsHeading'>Emaily</p>

                            <ul>
                                <li className='settingsRow'>
                                        <p>Potvrdenie objednávky</p>
                                        <GreenSwitch checked={this.state.settings.confirmation_email == 1 ? true : false} onChange={(e) => this.updateSetting('confirmation_email', this.state.settings.confirmation_email == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.confirmation_email == 1 ? true : false} classes={{container:'collapseEl'}} unmountOnExit mountOnEnter>
                                    <li className='settingsRow'>                                    
                                        <p>Predmet</p>
                                        <input type='text' value={this.state.settings.confirmation_email_subject} onBlur={(e) => this.updateSetting('confirmation_email_subject', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,confirmation_email_subject:e.target.value}})} className='settingsInput' placeholder='Objednávka bola úspešne odoslaná do reštaurácie' />
                                    </li>
                                    <li className='settingsRow'>                                    
                                        <p>Obsah</p>
                                        <input type='text' value={this.state.settings.confirmation_email_body} onBlur={(e) => this.updateSetting('confirmation_email_body', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,confirmation_email_body:e.target.value}})} className='settingsInput' placeholder='Ďakujeme za vašu objednávku, číslo objednávky je' />
                                    </li>
                                
                                </Collapse>

                                <li className='settingsRow'>
                                    <p>Objednávka smeruje do kuchyne</p>
                                    <GreenSwitch checked={this.state.settings.inkitchen_email == 1 ? true : false} onChange={(e) => this.updateSetting('inkitchen_email', this.state.settings.inkitchen_email == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.inkitchen_email == 1 ? true : false} classes={{container:'collapseEl'}} unmountOnExit mountOnEnter>
                                    <li className='settingsRow'>                                    
                                        <p>Predmet</p>
                                        <input type='text' value={this.state.settings.inkitchen_email_subject} onBlur={(e) => this.updateSetting('inkitchen_email_subject', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,inkitchen_email_subject:e.target.value}})} className='settingsInput' placeholder='Objednávka bola úspešne odoslaná do reštaurácie' />
                                    </li>
                                    <li className='settingsRow'>                                    
                                        <p>Obsah</p>
                                        <input type='text' value={this.state.settings.inkitchen_email_body} onBlur={(e) => this.updateSetting('inkitchen_email_body', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,inkitchen_email_body:e.target.value}})} className='settingsInput' placeholder='Ďakujeme za vašu objednávku, číslo objednávky je' />
                                    </li>
                                
                                </Collapse>

                                <li className='settingsRow'>
                                    <p>Objednávka vyráža na cestu</p>
                                    <GreenSwitch checked={this.state.settings.onway_email == 1 ? true : false} onChange={(e) => this.updateSetting('onway_email', this.state.settings.onway_email == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.onway_email == 1 ? true : false} classes={{container:'collapseEl'}} unmountOnExit mountOnEnter>
                                    <li className='settingsRow'>                                    
                                        <p>Predmet</p>
                                        <input type='text' value={this.state.settings.onway_email_subject} onBlur={(e) => this.updateSetting('onway_email_subject', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,onway_email_subject:e.target.value}})} className='settingsInput' placeholder='Objednávka bola úspešne odoslaná do reštaurácie' />
                                    </li>
                                    <li className='settingsRow'>                                    
                                        <p>Obsah</p>
                                        <input type='text' value={this.state.settings.onway_email_body} onBlur={(e) => this.updateSetting('onway_email_body', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,onway_email_body:e.target.value}})} className='settingsInput' placeholder='Ďakujeme za vašu objednávku, číslo objednávky je' />
                                    </li>
                                </Collapse>

                                <li className='settingsRow'>
                                    <p>Objednávka pripravená na vyzdvihnutie</p>
                                    <GreenSwitch checked={this.state.settings.pickupready_email == 1 ? true : false} onChange={(e) => this.updateSetting('pickupready_email', this.state.settings.pickupready_email == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.pickupready_email == 1 ? true : false} classes={{container:'collapseEl'}} unmountOnExit mountOnEnter>
                                    <li className='settingsRow'>                                    
                                        <p>Predmet</p>
                                        <input type='text' value={this.state.settings.pickupready_email_subject} onBlur={(e) => this.updateSetting('pickupready_email_subject', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,pickupready_email_subject:e.target.value}})} className='settingsInput' placeholder='Objednávka bola úspešne odoslaná do reštaurácie' />
                                    </li>
                                    <li className='settingsRow'>                                    
                                        <p>Obsah</p>
                                        <input type='text' value={this.state.settings.pickupready_email_body} onBlur={(e) => this.updateSetting('pickupready_email_body', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,pickupready_email_body:e.target.value}})} className='settingsInput' placeholder='Ďakujeme za vašu objednávku, číslo objednávky je' />
                                    </li>
                                </Collapse>

                                <li className='settingsRow'>
                                    <p>Objednávka bola vybavená</p>
                                    <GreenSwitch checked={this.state.settings.complete_email == 1 ? true : false} onChange={(e) => this.updateSetting('complete_email', this.state.settings.complete_email == 1 ? 0 : 1 )} />
                                </li>

                                <Collapse in={this.state.settings.complete_email == 1 ? true : false} classes={{container:'collapseEl'}} unmountOnExit mountOnEnter>
                                    <li className='settingsRow'>                                    
                                        <p>Predmet</p>
                                        <input type='text' value={this.state.settings.complete_email_subject} onBlur={(e) => this.updateSetting('complete_email_subject', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,complete_email_subject:e.target.value}})} className='settingsInput' placeholder='Objednávka bola úspešne odoslaná do reštaurácie' />
                                    </li>
                                    <li className='settingsRow'>                                    
                                        <p>Obsah</p>
                                        <input type='text' value={this.state.settings.complete_email_body} onBlur={(e) => this.updateSetting('complete_email_body', e.target.value)} onChange={(e) => this.setState({settings:{...this.state.settings,complete_email_body:e.target.value}})} className='settingsInput' placeholder='Ďakujeme za vašu objednávku, číslo objednávky je' />
                                    </li>
                                </Collapse>
                            </ul>
                        </div>        


                        <div className='settingsSection'>
                            <p className='settingsHeading'>Informácie o aplikácii</p>
                            <ul>
                                <li className='settingsRow'>
                                    <p>Technická podpora</p>
                                    <a href='mailto:info@foodia.sk' className='settingsLink'>info@foodia.sk</a>
                                </li>
                                <li className='settingsRow'>
                                    <p>Posledná aktualizácia</p>
                                    <p>12/12/2022</p>
                                </li>
                            </ul>
                        </div>      
                    </div>

                </div>
            )
        }
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));


const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(primaryColor),
      backgroundColor: primaryColor,
      '&:hover': {
        backgroundColor: primaryColor,
      },
    },
  }))(Button);

const ToolTipCustom = (props) => {
    return <Tooltip style={{marginLeft:'5px'}} title={<p className='tooltipText'>{props.text}</p>} placement={props.placement == null ? 'right-start' : props.placement} aria-label="add"><HelpOutlineIcon fontSize='small' /></Tooltip>
}

const NewDriver = (props) => {
    const [name, setName] = useState('');

    function handleChange(val){
        setName(val);
        props.setName(val);
    }

    return(
        <div className='newDriverModal'>
            <CssTextField value={name} onChange={(e) => handleChange(e.target.value)} placeholder='Meno' />
        </div>
    )
}

const NewSubdomain = (props) => {
    const [domain, setDomain] = useState('');

    function handleChange(val){
        var pattern = /\s/g;
        var result = domain.match(pattern);
        if(result == true){
            alert('Medzeri nie sú povolené');
            return false;
        }

        setDomain(val);
        props.setDomain(val)
    }

    return(
        <div className='subdomainOuter'>
            <div className='subdomainDiv'>
                    <CssTextField onChange={(e) => handleChange(e.target.value)} placeholder="meno-reštaurácie" />
            </div>
            <div className='subdomainView'>
                <p>https://</p>
                <p>{domain}</p>
                <p>.foodia.sk</p>
            </div>
        </div>
    )
}
