import React from 'react';

import './history.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DataGrid } from '@material-ui/data-grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import LoaderSpinner from '.././loader';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {ENDPOINT} from '../../GlobalComponents';
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Swal from 'sweetalert2';
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';

import TableRowCustom from './TableRowComponent';

const MySwal = withReactContent(Swal);

const transport = axios.create({
    withCredentials: true
});

export default class History extends React.Component{
    constructor(props){
        super(props);

        this.state = {
          orders:{},
          numOrders:0,
          loading:true,
          numTotal:0,
          page_size:25,
          page:1
        }

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.loadOrders = this.loadOrders.bind(this);

    }

    loadOrders(){
      transport.get(ENDPOINT + "/orders?page_size=" + this.state.page_size + "&page=" + this.state.page)
        .then(res => {
          var sumTotal = res.data.data.data.reduce(function(prev, cur) {
            return prev + parseFloat(cur.price);
          }, 0);

          this.setState({
            orders:res.data.data.data,
            numOrders:res.data.data.total,
            loading:false,
            numTotal:sumTotal
          })
        })
    }


    componentDidMount(){
      this.loadOrders();
    }

    handleChangePage(e){
      console.log(e);
      this.setState({
        page:Number(e.target.outerText)
      }, () => {
        console.log(this.state.page);
        this.loadOrders();
      })
    }

    handleChangeRowsPerPage(val){
      console.log(val);
      this.setState({
        page_size:5
      }, () => {
        this.loadOrders();
      })
    }

    render(){
      if(this.state.loading){
        return <LoaderSpinner />
      }else{
        return(
          <div id='mainHistory' style={{marginTop:'3em' }}>
            <div className='firstRow'>
              {/*
              <div className='dataContainer'>
                <h4>{this.props.numTotal.toFixed(2)} €</h4>
                <p>Spolu</p>
              </div>
              */}
            </div>
            <div style={{ height: 'auto'}} className='tableOuter'>
										{this.state.orders.length == 0 ? 
														<Card variant="outlined">
																		<CardContent>
																			<Alert severity="info">Zatiaľ ste neprijali žiadne objednávky</Alert>
																		</CardContent>
														</Card>
																			:


                <TableContainer component={Paper} className='tableDiv'>
                  <Table aria-label="simple table" style={{ tableLayout: 'auto' }} fixedHeader={false}>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left' style={{width:'10%'}} />
                        <TableCell align='left'><b>Číslo Objednávky</b></TableCell>
                        <TableCell align="left"><b>Meno</b></TableCell>
                        <TableCell align="left"><b>Email</b></TableCell>
                        <TableCell align="left"><b>Dovoz</b></TableCell>
                        <TableCell align="left"><b>Cena</b></TableCell>
                        <TableCell align="left"><b>Čas</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    
                    {this.state.orders.map((order) => {
                      return(
                        <TableRowCustom order={order} />
                      )
                    })}
                        
                    </TableBody>
                  </Table>
                  {/*
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.numOrders}
                    rowsPerPage={this.state.page_size}
                    page={this.state.page}
                    onPageChange={(e) => this.handleChangePage(e.target.value)}
                    onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e.target.value)}
                  />
                  */}
                  <div style={{textAlign: 'center', marginTop: '10px',display:'flex', justifyContent: 'center'}}>
                  <Pagination count={Math.ceil(this.state.numOrders/25)} page={this.state.page} onChange={(e) => this.handleChangePage(e)} hidePrevButton hideNextButton/>
                  </div>
                </TableContainer>
										}
               
            </div>
            </div>
        )
      }
    }
}
