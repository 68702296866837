import '../../App.scss';
import Checkbox from '@material-ui/core/Checkbox';
import {GreenCheckbox, CssTextField, ENDPOINT} from '../../GlobalComponents';
import { FormControlLabel } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { transport } from '../../App.js'
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const Register = (props) => {
  const [password, setPassword] = useState()
  const [passwordRepeat, setPasswordRepeat] = useState()
  const [passwordErr, setPasswordErr] = useState(false)
  const [showPassword, setShowPassword] = useState(false)


  const [email, setEmail] = useState()
  const [emailErr, setEmailErr] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')

  const [loading, setLoading] = useState(false)


  const handleKeyPress = (e) => {
    if (e.code === 'Enter') {
          registerFunc()	
    }
  }

  const registerFunc = () => {
    if(!email){
      setEmailErr(true)
      return
    }

    if(!password || !passwordRepeat){
      setPasswordErr(true)
      return
    }

    if(password !== passwordRepeat){
      setPasswordErr(true)
      setAlertOpen(true)
      setAlertMsg('Heslá sa nezhodujú')
      return
    }

    setEmailErr(false)
    setPasswordErr(false)
    setAlertMsg('')
    setAlertOpen(false)

    setLoading(true)

    let payload = JSON.stringify({
      email:email,
      username:'',
      password:password,
    })

    transport.post(ENDPOINT + "/register", payload)
      .then(res => {
					alert('Skontrolujte si emailovú adresu pre potvrdenie')
          setLoading(false)
      }).catch(err => {
        setAlertOpen(true)
        setAlertMsg('Nastala chyba, prosím kontaktujte nás na 0903 740 155 alebo info@foodia.sk')
        setLoading(false)
      })
    }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
    event.preventDefault();
    }


    return(
      <>
        <div className='loginMain' onKeyPress={handleKeyPress}>

        <div className='container loginForm'>
          <p style={{marginBottom:'0.5em'}}>Registrácia</p>

          <div>

          {alertOpen ? <Alert severity="error">{alertMsg}</Alert> : null}

            <div class="form-group">
              <TextField error={emailErr} label="Email" variant="outlined" color="primary" fullWidth onChange={(e) => setEmail(e.target.value)} margin="normal" name="email" type="email" />
            </div>

									{/*
            <div class="form-group">
              <label for="exampleInputEmail1">Užívateľské meno</label>
              <input type="text" class="form-control loginInputStyle" id="registerUsername" aria-describedby="emailHelp" placeholder="firma123" onChange={(e) => props.setUsername(e.target.value)} />
            </div>
						*/}

            <div class="form-group">
              <TextField error={passwordErr} name="password" label="Heslo" type={showPassword ? "text" : "password"} variant="outlined" color="primary" fullWidth onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                          endAdornment:	<IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>

                          }}
                  />
            
            </div>

            <div class="form-group">
              <TextField error={passwordErr} name="password" label="Zopakuj heslo" type={showPassword ? "text" : "password"} variant="outlined" color="primary" fullWidth onChange={(e) => setPasswordRepeat(e.target.value)}
                  InputProps={{
                          endAdornment:	<IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>

                          }}
                  />
            </div>

            <br />
						<Button onClick={registerFunc} color="primary" variant="contained" fullWidth size="large" disabled={loading}>Zaregistrovať sa</Button>
            <Button onClick={() => props.changeRouteTo('#/login')} color="primary" fullWidth size="large" style={{marginTop:'1em'}}>Prihlásenie</Button>


          </div>
        </div>
      </div>
      </>
    )
}

export default Register;
