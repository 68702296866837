import './dashboard.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import LoaderSpinner from '../loader';
import axios from 'axios';
import foodia_background_logo from '../menu/foodiadžsede.svg';
import { Pie } from 'react-chartjs-2';

export default class ChartPie extends React.Component {
    constructor(props) {
      super(props);
   
      this.state = {
        data: {
          labels: ['Nové', 'V kuchyni', 'Na ceste'],
          responsive:true,
          datasets: [{
              label: 'Objednavky aktualne',
              data: this.props.data,
              backgroundColor: ['#dc3545','#ffc107','#28a745'],
              borderWidth: 1
          }],
          options:{
            legend:{
              position:'left'
            }
          }
      },
       
      }
    }
    render() {
      if(this.props.data.reduce((a,b) => a +b) == 0){
        return null;
      }else{
      return (
        <Pie data={this.state.data} options={this.state.options} height={300} width={300} />
      )}
    }
  }