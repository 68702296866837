import { useEffect, useState } from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import { ENDPOINT } from '../../GlobalComponents.js';
import { Box, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Rating from '@material-ui/lab/Rating';

const req = axios.create({
    withCredentials: true
})

function LastReviews(){
				const [loading, setLoading] = useState(true)
				const [reviews, setReviews] = useState()

				useEffect(() => {

								fetchData()

				}, [])

				const fetchData = () => {
								req.get(ENDPOINT + '/reviews-admin/5')
									.then(res => {
													setReviews(res.data.data.reviews)
													setLoading(false)
									})
				}

				if(loading){
								return <Skeleton variant="rect" width="100%" height={250} style={{borderRadius:'10px'}}/>

				}

				return(
								<>
												<Card className="dashboardItem">
																<Box margin={3}>
																				<CardHeader title={<h4>Najnovšie recenzie</h4>} />
																				<CardContent>

																				{reviews.length == 0 ? 
																											<Alert severity="info">Zatiaľ ste neprijali žiadne recenzie.</Alert>
																											:
																								
																		<TableContainer>
																				<Table size='small'>
																						<TableHead>
																								<TableRow>
																										<TableCell style={{fontWeight:'bold'}}>Číslo objednávky</TableCell>
																										<TableCell style={{fontWeight:'bold'}}>Hodnotenie</TableCell>
																										<TableCell style={{fontWeight:'bold'}}>Správa</TableCell>
																								</TableRow>
																						</TableHead>
																						<TableBody>
																								{reviews.map(item => {
																												
																												return(
																														<TableRow>
																																<TableCell>#{item.order_id}</TableCell>
																																<TableCell>
																																	<Rating name="read-only" value={item.rating} readOnly />
																																</TableCell>
																																<TableCell>{item.content}</TableCell>
																														</TableRow>
																												)
																								})}
																						</TableBody>
																				</Table>
																		</TableContainer>
																								}


																				</CardContent>
																</Box>

												</Card>
												

								</>
				)
}

export default LastReviews
