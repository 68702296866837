import '../../App.scss';
import Checkbox from '@material-ui/core/Checkbox';
import {GreenCheckbox, CssTextField, ENDPOINT} from '../../GlobalComponents';
import { FormControlLabel } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { transport } from '../../App.js'
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const Login = (props) => {

				const [showPassword, setShowPassword] = useState(false)
				const [loading, setLoading] = useState(false)

				const [email, setEmail] = useState('')
				const [password, setPassword] = useState('')

				const [emailErr, setEmailErr] = useState(false)
				const [passwordErr, setPasswordErr] = useState(false)

				const [alertOpen, setAlertOpen] = useState(false) 
				const [alertMsg, setAlertMsg] = useState('')

			  const [t, i18n] = useTranslation('common');

				const [language, setLanguage] = useState('sk')

				const handleKeyPress = (e) => {
										if (e.code === 'Enter') {
													loginFunc()	
										}
				}

				const handleClickShowPassword = () => {
									setShowPassword(!showPassword)
				}
				  const handleMouseDownPassword = (event) => {
						event.preventDefault();
					};


				const loginFunc = () => {

						let rememberCheckbox = document.getElementById('rememberLogin');
						let remember = rememberCheckbox.checked ? 1 : 0;

						 if(!email){
										setEmailErr(true)
										return
							} 

							if(!password){
											setPasswordErr(true)
											return
							}

							setLoading(true)	

							let payload = JSON.stringify({
								email: email,
								password: password,
								remember:remember,
							});
							
							transport.post(ENDPOINT + "/login", payload)
								.then(res => {
									if(res.data.data == "Logged In"){
													setLoading(false)
													props.loginSuccess()
									}

								}).catch(err => {
												let msg = err.response.data.data
												setAlertMsg(msg)
												setAlertOpen(true)
												setLoading(false)
								})
        
					}
		
    return(
						<>
										<div onKeyPress={handleKeyPress} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div className='container loginForm'>
              <p style={{marginBottom:'0.5em'}}>{t('login.login_page')}</p>
              <div>
											{alertOpen ? <Alert severity="error">{alertMsg}</Alert> : null}
                <div class="form-group">
												<TextField error={emailErr} label={t('login.email')} variant="outlined" color="primary" fullWidth onChange={(e) => setEmail(e.target.value)} margin="normal" name="email" type="email" />
                </div>
                <div class="form-group">
												<TextField error={passwordErr} name="password" label={t('login.password')} type={showPassword ? "text" : "password"} variant="outlined" color="primary" fullWidth onChange={(e) => setPassword(e.target.value)}

																InputProps={{
																				endAdornment:	<IconButton
																									aria-label="toggle password visibility"
																									onClick={handleClickShowPassword}
																									onMouseDown={handleMouseDownPassword}
																								>
																									{showPassword ? <Visibility /> : <VisibilityOff />}
																								</IconButton>

																				}}
												/>

                </div>
                <div class="form-check">
                    <FormControlLabel
                        control={<GreenCheckbox id='rememberLogin' name="checkedG" />}
                        label={t('login.remember_login')}
                    />
                </div>
                <div className='form-forgot'>
                  <Link component='button' onClick={() => props.changeRouteTo('#/forgot-password')} color="primary">{t('login.forgot_password')}</Link>
                </div>

											<br />
											<Button onClick={loginFunc} color="primary" variant="contained" fullWidth size="large" disabled={loading}>{t('login.login')}</Button>
											<Button onClick={() => props.changeRouteTo('#/register')} color="primary" fullWidth size="large" style={{marginTop:'1em'}}>Registrácia</Button>

              </div>
								
            </div>
			{/*
										<FormControl>
												<Select
													style={{marginTop:"1em"}}			
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={i18n.language}
													onChange={(e) => i18n.changeLanguage(e.target.value)}
													margin="normal"
												>
																<MenuItem value='sk'>&#127480;&#127472; Slovenčina</MenuItem>
													<MenuItem value='en'>&#127468;&#127463; English</MenuItem>
												</Select>
											</FormControl>
																			*/}

									
          </div>
										 
						</>
    )
}

export default Login;
