import "./objednavky.scss";
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
} from "react-router-dom";
import axios from "axios";
import Nastavenia from "../menu/menu";
import Objednavky from "./objednavky";
import Tooltip from "@material-ui/core/Tooltip";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
	Box,
	FormControl,
	InputLabel,
	NativeSelect,
	Select,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation, withTranslation} from "react-i18next";

const MySwal = withReactContent(Swal);

const transport = axios.create({
	withCredentials: true,
});

class Note extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			opened: false,
			new: true,
			isConfirmed: false,
			isLoading: true,
			selectedDriver: ""
		};

		this.expandNote = this.expandNote.bind(this);
		this.closeNote = this.closeNote.bind(this);
		this.showDetails = this.showDetails.bind(this);
		this.selectDriver = this.selectDriver.bind(this);
	}

	closeNote(e) {
		this.setState({
			opened: false,
		});
	}
	expandNote(e) {
		this.setState({
			opened: true,
		});
	}

	selectDriver(val) {
		this.setState({
			selectedDriver: val,
		});
	}

	showDetails() {
		MySwal.fire({
			html: (
											<div id="detailsDiv">
					<div className="detailDiv">
						<i class="material-icons">
							note
						</i>
						<p>{this.props.order.note}</p>
					</div>
					<div className="detailDiv">
						<i class="material-icons">
							mail
						</i>
						<p>{this.props.order.email}</p>
					</div>
					<div className="detailDiv">
						<i class="material-icons">
							call
						</i>
						<p>
							{
								this.props.order
									.phone_num
							}
						</p>
					</div>
					{this.props.order.delivery == 1 ? (
						<div className="detailDiv">
							<i class="material-icons">
								location_on
							</i>
							<p>
								{
									this
										.props
										.order
										.address
								}
							</p>
						</div>
					) : null}
				</div>
			),
			showCloseButton: true,
			showConfirmButton: false,
		});
	}

	render() {
		const { t } = this.props;

		let colorSet = "";

		switch (this.props.order.status) {
			case "new":
				colorSet = "#dc3545";
				break;
			case "inKitchen":
				colorSet = "#ffc107";
				break;
			case "onWay":
				colorSet = "#28a745";
				break;
			case "pickupReady":
				colorSet = "#28a745";
				break;
		}

		return (
			<div>
				<div className="orderThumb">
					<div
						className="headerNote"
						onClick={this.showDetails}
						style={{
							backgroundColor: colorSet,
						}}
					>
						<div className="leftSide">
							<p className="nameNote">
								{
									this
										.props
										.order
										.name
								}
							</p>
							<p className="dateNote">
								{
									this.props.order.date.split(
										" "
									)[1]
								}
							</p>
						</div>
						<div className="rightSide">
							{this.props.order
								.delivery ==
							1 ? (
								<p className="nameNote">
												{t('orders_section.delivery')}
								</p>
							) : this.props.order
									.table_service ==
							  1 ? (
								<p className="nameNote">
												{t('orders_section.to_table')}
									{" "}
									{
										this
											.props
											.order
											.table_num
									}
								</p>
							) : (
								<p className="nameNote">
												{t('orders_section.pick_up')}	
								</p>
							)}
							<p className="floatRight">
								{
									this
										.props
										.order
										.price
								}{" "}
								€ | #
								{
									this
										.props
										.order
										.orderID
								}
							</p>
						</div>
					</div>
					<div className="bodyNote">
						<ul>
							{this.props.content.map(
								(order) => {
									return (
										<li>
											<p className="amountSpan">
												{
													order.amount
												}

												x
											</p>
											<div className="itemName">
													<p style={{display:'flex'}}><p>{order.food}</p>
													{order.sizes ==
													undefined
														? null
														: order.sizes.map((size) => {
																  return (
																			 <p style={{fontWeight:'bold'}}>{' - ' + size.name}</p>
																  )
														  })}
												</p>
																			{order.option.length > 0 ? (
													<p className="grey">
														+{" "}
														{
															order.option
														}
													</p>
												) : null}
												{order.additions ==
												undefined || order.additions.length == 0
													? null
													: order.additions.map(
															(
																addition
															) => {
																return (
																	<p className="grey">
																		+{" "}
																		{
																			addition.name
																		}
																	</p>
																);
															}
													  )}
											</div>
										</li>
									);
								}
							)}
						</ul>

													{this.props.order.note ? 
																					<div style={{background:'yellow', padding:'5px', borderRadius:'5px', wordWrap:'break-word'}} className="shadow">
																				Poznámka: {this.props.order.note}

												</div>
												: null }
					</div>
											
					<div className="footerNote">
						{this.props.order.delivery ==
						1 ? (
							<Tooltip
								title={
									<p className="tooltipText">
													{t('orders_section.order_leaving_restaurant')}
									</p>
								}
							>
								<button
									type="button"
									onClick={() =>
										this.props.updateStatus(
											"onWay",
											this
												.props
												.order
												.orderID,
											this
												.props
												.order
												.email
										)
									}
								>
									<i class="material-icons">
										drive_eta
									</i>
								</button>
							</Tooltip>
						) : this.props.order.pickup ==
						  1 ? (
							<Tooltip
								title={
									<p className="tooltipText">
													{t('orders_section.order_ready_for_pickup')}
									</p>
								}
							>
								<button
									type="button"
									onClick={() =>
										this.props.updateStatus(
											"pickupReady",
											this
												.props
												.order
												.orderID,
											this
												.props
												.order
												.email
										)
									}
								>
									<i class="material-icons">
										restaurant
									</i>
								</button>
							</Tooltip>
						) : null}
						<Tooltip
							title={
								<p className="tooltipText">
												{t('orders_section.order_going_to_kitchen')}
								</p>
							}
						>
							<button
								type="button"
								onClick={() =>
									this.props.updateStatus(
										"inKitchen",
										this
											.props
											.order
											.orderID,
										this
											.props
											.order
											.email
									)
								}
							>
								<i class="material-icons">
									kitchen
								</i>
							</button>
						</Tooltip>
						<Tooltip
							title={
								<p className="tooltipText">
										{t('orders_section.order_is_complete')}
								</p>
							}
						>
							<button
								type="button"
								onClick={() =>
									this.props.updateStatus(
										"complete",
										this
											.props
											.order
											.orderID,
										this
											.props
											.order
											.email
									)
								}
							>
								<i className="material-icons">
									check
								</i>
							</button>
						</Tooltip>
					</div>
				</div>
			</div>
		);
	}
}

const ChooseDriver = (props) => {
	function handleChange(e) {
		props.changeDriver(e.target.value);
	}

	return (
		<Box margin={3}>
			<FormControl>
				<select
					className="selectStyle"
					onChange={(e) => handleChange(e)}
				>
					<option selected disabled value="false">
						Vyberte šoféra
					</option>
					{props.drivers.map((driver) => {
						return (
							<option
								value={
									driver.driver_name
								}
							>
								{
									driver.driver_name
								}
							</option>
						);
					})}
				</select>
			</FormControl>
		</Box>
	);
};


const NoteTranslated = withTranslation('common')(Note)
export default NoteTranslated 
