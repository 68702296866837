import './Reviews.scss';
import React, { useState, useEffect } from 'react';

import { ENDPOINT } from '../../GlobalComponents.js';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { Alert, AlertTitle } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Rating from '@material-ui/lab/Rating';
import LoaderSpinner from '../loader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';


const req = axios.create({
    withCredentials: true
});

function Reviews(props){
				const [loading, setLoading] = useState(true)
				const [reviews, setReviews] = useState()
				const [limit, setLimit] = useState(15)
				const [iframePreviewModal, setIframePreviewModal] = useState(false)
				const [codeSnippet] = useState('<iframe src="' + props.settings.foodia_url + '/#/recenzie" title="Foodia Recenzie" style="width:100%;min-height:500px">')

				useEffect(() => {
								fetchData()
				}, [])

				const fetchData = () => {
								setLoading(true)
								req.get(ENDPOINT + '/reviews-admin/' + limit)
									.then(res => {
													console.log(res.data.data.reviews)
													setReviews(res.data.data.reviews)
													setLoading(false)
									}).catch(err => {
													console.log(err)
									})
				}

				const loadMore = () => {
								setLimit(limit + 10)
								fetchData()
				}

				const removeReview = (id) => {
					if(window.confirm('Naozaj chcete vymazať recenziu ' + id + ' ?')){

						req.delete(ENDPOINT + '/reviews-admin/' + id)
							.then(res => {
								console.log(res.data)
								fetchData()
							}).catch(err => {
								alert(err)
							})

					}
				
					console.log(id)
				}

				const copyToClibboard = (text) => {
					navigator.clipboard.writeText(codeSnippet)
					props.showAlert('success', 'Skopírované !')

				}


				if(loading) return <LoaderSpinner />

				return(
								<>
												<Container maxWidth="lg" style={{marginTop:'3em', marginBottom:'3em'}}>
																<h3>Recenzie</h3>
																<Divider />
																<Button variant="contained" color="primary" style={{marginTop:'1.5em'}} onClick={() => setIframePreviewModal(true)}>Vložiť recenzie na môj web</Button>

																{
																				reviews.length == 0 ? 

																								<Alert severity="info" style={{marginTop:'1.5em'}}>
																								<AlertTitle>Info
																								</AlertTitle>
																												Zatiaľ ste neprijali žiadne recenzie. Vaši zákazníci môžu zanechať recenziu kliknutím na link v emaili o vybavenej objednávke.
																</Alert>
																: null
																}

																{reviews.map(item => {
																				return (
																								<Card variant="" style={{marginTop:'1.5em'}}>
																												<CardContent style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
																													<div>
																																<h3>#{item.order_id}</h3>
																																        <Rating name="read-only" value={item.rating} readOnly />

																																<p>{item.content}</p>
																																<p style={{marginTop:'1em', fontSize:"smaller", color:'grey'}}>{item.created}</p>
																																</div>
																																<IconButton aria-label="delete" onClick={() => removeReview(item.review_id)}>
																																	<DeleteIcon />
																																</IconButton>
																																
																												</CardContent>

																								</Card>

																				) 
																})}


																{reviews.total_items > limit ? 
																<div style={{display:'flex', justifyContent:'center', marginTop:'1em'}}>
																				<Button color="primary" onClick={loadMore}>Načítať viac</Button>
																</div>
																: null}

												</Container>

												<Dialog
												open={iframePreviewModal}
												onClose={() => setIframePreviewModal(false)}

											>
												<DialogTitle id="alert-dialog-title">{"Pridajte recenzie na svoj web!"}</DialogTitle>
												<DialogContent>
													<DialogContentText id="alert-dialog-description">
														Skopírovaním tohoto HTML kódu pridáte recenzie od vašich zákazníkov na váš firemný web.
												</DialogContentText>

																<Box component="div" style={{display:'flex', flexDirection:'column'}}>
																<Button onClick={() => copyToClibboard(codeSnippet)} style={{margin:'1em 0em 1em 0em'}} color="primary">Skopírovať</Button>

																				<TextField label="HTML Kód" variant="filled" 
																					value={codeSnippet}
																					readOnly
																				/>
																	
																</Box>
												</DialogContent>
												<DialogActions>
													<Button color="primary" variant="contained" onClick={() => setIframePreviewModal(false)}>
													Zatvoriť	
													</Button>
												</DialogActions>
											</Dialog>

								</>
				)
}

export default Reviews 
